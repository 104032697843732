import React from 'react';

import CreateGuidePage from '../components/CreateGuide';

class CreateGuide extends React.Component {
  render() {
    return (
      <div>
        <CreateGuidePage />
      </div>
    );
  }
}

export default CreateGuide;
