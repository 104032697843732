import React from 'react';

import InfoPage from '../components/Info';

class Info extends React.Component {
  render() {
    return (
      <div>
        <InfoPage />
      </div>
    );
  }
}

export default Info;
