import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { Select, Divider } from 'antd';
import isMobile from 'ismobilejs';

import Header from './Header';
import GuideHeader from './guide/header';
import Footer from './Footer';

import '../assets/css/help.scss';

import guide1 from '../assets/images/guide/1.png';
import guide2 from '../assets/images/guide/2.png';
import guide3 from '../assets/images/guide/3.png';
import guide4 from '../assets/images/guide/4.png';

import guide1_en from '../assets/images/guide/1_en.png';
import guide2_en from '../assets/images/guide/2_en.png';
import guide3_en from '../assets/images/guide/3_en.png';
import guide4_en from '../assets/images/guide/4_en.png';

import mobileGuide1 from '../assets/images/guide/1.png';
import mobileGuide2 from '../assets/images/guide/2.png';
import mobileGuide3 from '../assets/images/guide/m3.png';
import mobileGuide4 from '../assets/images/guide/m4.png';

import mobileGuide1_en from '../assets/images/guide/1_en.png';
import mobileGuide2_en from '../assets/images/guide/2_en.png';
import mobileGuide3_en from '../assets/images/guide/m3_en.png';
import mobileGuide4_en from '../assets/images/guide/m4_en.png';

import bottomImg from '../assets/images/web/kv_bg.png';

const { Option } = Select;

@inject('ztron')
@observer
class Help extends React.Component {
  constructor(props) {
    super(props);
    this.unmount = false;
    this.state = {
      iosDownLink: '',
      AndroidDownLink: '',
      lang: window.localStorage.getItem('lang') || intl.options.currentLocale,
      mobileImgs: [mobileGuide1, mobileGuide2, mobileGuide3, mobileGuide4],
      mobileImgs_en: [mobileGuide1_en, mobileGuide2_en, mobileGuide3_en, mobileGuide4_en],
      PCImgs: [guide1, guide2, guide3, guide4],
      PCImgs_en: [guide1_en, guide2_en, guide3_en, guide4_en]
    };
  }

  componentDidMount() {
    this.unmount = false;
    this.props.ztron.setLocationStr('help');
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  setLanguage = lang => {
    window.localStorage.setItem('lang', lang);
    window.location.search = `?lang=${lang}`;
  };

  render() {
    let { mobileImgs, mobileImgs_en, PCImgs, PCImgs_en } = this.state;
    let ImgArr = [];
    if (isMobile(window.navigator).any) {
      if (this.state.lang === 'en-US') {
        ImgArr = mobileImgs_en;
      } else {
        ImgArr = mobileImgs;
      }
    } else {
      if (this.state.lang === 'en-US') {
        ImgArr = PCImgs_en;
      } else {
        ImgArr = PCImgs;
      }
    }
    let windowWidth = document.body.clientWidth || document.documentElement.clientWidth;
    return (
      <div className="helpCenter">
        {isMobile(window.navigator).any || windowWidth < 1000 ? <Header /> : <GuideHeader />}
        <div className="helpContent">
          <h3 className="title">{intl.get('help_text_title')}</h3>
          <section className="manual">
            <li>
              <h4>{intl.get('help_text_1')}</h4>
            </li>
            <li className="single">
              <p className="mt10">{intl.get('help_text_2')}</p>
              <img src={ImgArr[0]} className="img0" />
            </li>
            <li>
              <h4>{intl.get('help_text_3')}</h4>
              <p>{intl.getHTML('help_text_3_1')}</p>
              <p>{intl.get('help_text_3_2')}</p>
              <p>{intl.get('help_text_3_3')}</p>
              <p className="Note">{intl.get('help_text_3_tip')}</p>
              <img src={ImgArr[1]} className="img1" />
            </li>
            <li>
              <h4>{intl.get('help_text_4')}</h4>
              <p>{intl.getHTML('help_text_4_1')}</p>
              <p>{intl.get('help_text_4_2')}</p>
              <p>{intl.get('help_text_4_3')}</p>
              <p>{intl.get('help_text_4_4')}</p>
              <p>{intl.get('help_text_4_5')}</p>
              <p className="Note">{intl.get('help_text_4_tip')}</p>
              <img src={ImgArr[2]} className="img2" />
            </li>
            <li>
              <h4>{intl.get('help_text_5')}</h4>
              <p>{intl.get('help_text_5_tip')}</p>
              <img src={ImgArr[3]} className="img3" />
              <p className="purple">{intl.get('help_text_5_tip2')}</p>
            </li>
          </section>
        </div>
        <img src={bottomImg} className="topImg" />
        <img src={bottomImg} className="bottomImg" />
        <Footer />
      </div>
    );
  }
}

export default Help;
