import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Divider, Pagination, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import { cutMiddle, formatNumber, formatDate } from '../utils/helper';
import Config from '../config';

import '../assets/css/records.scss';

import box from '../assets/images/no-record.svg';
import arrow from '../assets/images/arrow.svg';

@inject('ztron')
@observer
class Records extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolTipRecords: false
    };
  }

  componentDidMount = async () => {
    this.props.ztron.setData('pageNo', 1);
    // this.props.ztron.getHistoryData();
    try {
      // wait for connecting
      if (this.props.ztron.priConnected) {
        this.props.ztron.getHistoryData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  onChange = pageNo => {
    this.props.ztron.setData('pageNo', Math.ceil(pageNo));
    this.props.ztron.getHistoryData();
  };

  mobileClick = (name, status) => {
    if (name === 'toolTipRecords') {
      this.setState({ toolTipRecords: status });
    }
  };

  render() {
    const { priConnected, pageSize, pageNo, history, privacyAddress, tokenSymbol } = this.props.ztron;
    const records = history.transactions || [];
    const totalNum = history.totalCount;
    const { toolTipRecords } = this.state;
    return (
      <div className="records">
        <div className="title">
          <span className="open">{intl.get('record_transactionRecord_title')}</span>
          <Tooltip
            title={<div className="account-tooltip-text">{intl.get('record_transactionRecord_tip')}</div>}
            overlayClassName="account-tooltip"
            placement="bottomLeft"
            color="rgba(27,31,38,0.90)"
          >
            <span
              onTouchStart={() => this.mobileClick('toolTipRecords', true)}
              onTouchEnd={() => this.mobileClick('toolTipRecords', false)}
              className={'tooltip-icon ' + (toolTipRecords ? 'touchover' : '')}
            ></span>
          </Tooltip>
        </div>
        {priConnected && records.length > 0 ? (
          <div>
            <div className="list">
              {records.map((item, index) => (
                <div className="item" key={`${item.txid}${index}`}>
                  <a href={`${Config.transaction}${item.txid}`} target="_blank">
                    <li>
                      {item.to === privacyAddress ? (
                        <Button className="collect-btn">
                          {intl.get('record_collect_text')} <img src={arrow} alt="" />
                        </Button>
                      ) : (
                        <Button className="transfer-btn">
                          {intl.get('record_transfer_text')} <img src={arrow} alt="" />
                        </Button>
                      )}
                      <span className="right time">{formatDate(item.time)}</span>
                    </li>
                    <li className="amount">
                      <span className="left">{intl.get('record_quantity_text')}</span>
                      <span className={'right ' + (item.to === privacyAddress ? 'right-receive' : '')}>
                        {item.to === privacyAddress ? '+' : '-'} {formatNumber(item.amount)} {tokenSymbol}
                      </span>
                    </li>
                    <li>
                      <span className="left">{intl.get('record_account_text')}</span>
                      <span className="right">{cutMiddle(item.to, 12, 12)}</span>
                    </li>
                    <li className="lh25">
                      <span className="left">{intl.get('record_txid_text')}</span>
                      <span className="right">{cutMiddle(item.txid, 14, 13)}</span>
                    </li>
                    <Divider className="divider"></Divider>
                  </a>
                </div>
              ))}
            </div>
            <div className="pagination">
              <Button onClick={e => this.onChange(1)}>{intl.get('record_firstPage_text')}</Button>
              <Pagination
                simple
                current={pageNo}
                onChange={this.onChange}
                total={totalNum}
                pageSize={pageSize}
                size="small"
              />
              <Button onClick={e => this.onChange(totalNum / pageSize)}>{intl.get('record_lastPage_text')}</Button>
            </div>
          </div>
        ) : (
          <div className="notLogin">
            <img src={box} alt="" />
            <p>{intl.get('record_none_text')}</p>
          </div>
        )}
      </div>
    );
  }
}

export default Records;
