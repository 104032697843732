import React from 'react';

import HelpPage from '../components/Help';

class Help extends React.Component {
  render() {
    return (
      <div>
        <HelpPage />
      </div>
    );
  }
}

export default Help;
