import React from 'react';
import { inject, observer } from 'mobx-react';
import isMobile from 'ismobilejs';
import { Select } from 'antd';
import intl from 'react-intl-universal';
import Config from '../config';
import fullLogo from '../assets/images/main-logo.svg';

const { Option } = Select;

@inject('ztron')
@observer
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: window.localStorage.getItem('lang') || intl.options.currentLocale
    };
  }

  componentDidMount() {}

  loadUrl = path => {
    location.replace(`/?lang=${this.state.lang}#/${path}`);
  };

  setLanguage = lang => {
    this.props.ztron.setLanguage(lang);
  };

  openLink = key => {
    if (key === 'api') {
      window.open(Config.APILink);
    } else if (key === 'github') {
      window.open(Config.githubLink);
    } else if (key === 'manual') {
      this.loadUrl('help');
    } else if (key === 'creation') {
      this.loadUrl('createguide');
    }
  };

  render() {
    const windowWidth = document.body.clientWidth || document.documentElement.clientWidth;

    return (
      <div className="footer">
        {isMobile(window.navigator).any || windowWidth < 1000 ? (
          <div className="footer-link">
            {/* <a href={Config.twitterLink} target="_blank" className="twitter"></a> */}
            <a href={Config.telegramLink} target="_blank" className="telegram"></a>
            {/* <a href={Config.githubLink} target="_blank" className="github"></a> */}
          </div>
        ) : (
          <div className="footer-flex">
            <div className="footer-category">
              <a href={Config.homeLink}>
                <img className="footer-logo" src={fullLogo} alt="zkWrapper" />
              </a>
              {/* <Select
                value={intl.get('product_menu_text2')}
                className="nav-set-lang"
                onChange={this.openLink}
                dropdownClassName="select-auto footer-select-auto"
              >
                <Option value="api">
                  <a href={Config.APILink} className="link-a">
                    {intl.get('menu_develop_api')}
                  </a>
                </Option>
                <Option value="github">
                  <a href={Config.githubLink} className="link-a">
                    {intl.get('menu_develop_github')}
                  </a>
                </Option>
              </Select> */}
              {/* <Select
                value={intl.get('product_menu_text3')}
                className="nav-set-lang"
                onChange={this.openLink}
                dropdownClassName="select-auto footer-select-auto"
              >
                <Option value="manual">
                  <a href={Config.helpLink} className="link-a">
                    {intl.get('menu_help_manual')}
                  </a>
                </Option>
                <Option value="creation">
                  <a href={Config.createGuideLink} className="link-a">
                    {intl.get('menu_help_creation')}
                  </a>
                </Option>
              </Select> */}
              {/* <Select
                defaultValue={this.state.lang}
                className="nav-set-lang"
                onChange={this.setLanguage}
                dropdownClassName="select-auto footer-select-auto"
              >
                <Option value="zh-CN">简体中文</Option>
                <Option value="en-US">English</Option>
              </Select> */}
            </div>
            <div className="footer-link">
              {/* <a href={Config.twitterLink} target="_blank" className="twitter"></a> */}
              <a href={Config.telegramLink} target="_blank" className="telegram"></a>
              {/* <a href={Config.githubLink} target="_blank" className="github"></a> */}
            </div>
          </div>
        )}
        <div className="footer-copyright">{intl.get('footer_copyright')}</div>
      </div>
    );
  }
}

export default Footer;
