import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { Tooltip, message } from 'antd';
import Config from '../config';
import { cutMiddle, formatNumber, copyToClipboard } from '../utils/helper';

import copyImg from '../assets/images/copy.svg';

@inject('ztron')
@observer
class AccountInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceDisable: false,
      trxState: '', // '' red
      bandwidthState: '',
      energyState: '',
      copyShiled: false,
      copyPublic: false,
      toolTipShiled: false,
      toolTipPublic: false,
      transBtn: false,
      receiveBtn: false,
      transToBtn: false
    };
  }

  componentDidMount() {
    this.props.ztron.setData('accountComponent', this);

    this.props.ztron.setNewbee();
    this.checkResource();
  }

  componentWillUnmount() {
    this.props.ztron.setData('accountComponent', {});
  }

  checkResource = () => {
    const { addressTrx, addressBandwidth, addressEnergy } = this.props.ztron;
    const { trxCost, bandwidthCost, energyCost } = Config;
    let resourceDisable = false;
    let trxState = '';
    let bandwidthState = '';
    let energyState = '';

    if (addressBandwidth === '--') return;

    if (addressBandwidth.lt(bandwidthCost)) {
      bandwidthState = 'red';
    }
    if (addressEnergy.lt(energyCost)) {
      energyState = 'red';
    }
    if (bandwidthState || energyState) {
      if (addressTrx.lt(trxCost)) {
        trxState = 'red';
      }
    }

    if (trxState) {
      resourceDisable = true;
    }

    this.setState({ resourceDisable, trxState, bandwidthState, energyState });
  };

  mobileClick = (name, status) => {
    if (name === 'copyShiled') {
      this.setState({ copyShiled: status });
    } else if (name === 'copyPublic') {
      this.setState({ copyPublic: status });
    } else if (name === 'toolTipShiled') {
      this.setState({ toolTipShiled: status });
    } else if (name === 'toolTipPublic') {
      this.setState({ toolTipPublic: status });
    } else if (name === 'transBtn') {
      this.setState({ transBtn: status });
    } else if (name === 'receiveBtn') {
      this.setState({ receiveBtn: status });
    } else if (name === 'transToBtn') {
      this.setState({ transToBtn: status });
    }
  };

  render() {
    const {
      blockData,
      privacyAddress,
      privacyAddressBalance,
      address,
      addressBalance,
      addressTrx,
      addressBandwidth,
      addressEnergy,
      tokenSymbol,
      nodeWaitSync
    } = this.props.ztron;
    const {
      resourceDisable,
      trxState,
      bandwidthState,
      energyState,
      copyShiled,
      copyPublic,
      toolTipShiled,
      toolTipPublic,
      transBtn,
      receiveBtn,
      transToBtn
    } = this.state;
    return (
      <div className="account-list">
        {/* 匿名账户 */}
        <div className="shield-account account-block">
          <div className="title shield">
            <span>{intl.get('acnt_shield')}</span>
            <Tooltip title={intl.get('trans_saccount_tips')} placement="bottomRight">
              <span
                onTouchStart={() => this.mobileClick('toolTipShiled', true)}
                onTouchEnd={() => this.mobileClick('toolTipShiled', false)}
                className={'tooltip-icon ' + (toolTipShiled ? 'touchover' : '')}
              ></span>
            </Tooltip>
          </div>
          <div className="account-info">
            <div className={'balance info ' + (formatNumber(privacyAddressBalance).length > 10 ? 'min' : '')}>
              <span>{formatNumber(privacyAddressBalance)}</span>
              <span className="uint"> {tokenSymbol}</span>
            </div>
            <div className="address">
              {cutMiddle(privacyAddress, 13, 14)}{' '}
              <span className="copy-link" title={privacyAddress} onClick={copyToClipboard}>
                <img
                  src={copyImg}
                  alt="copyImg"
                  onTouchStart={() => this.mobileClick('copyShiled', true)}
                  onTouchEnd={() => this.mobileClick('copyShiled', false)}
                  className={'copyBtn ' + (copyShiled ? 'touchover' : '')}
                />
              </span>
            </div>
          </div>
          <div className="action-btns">
            <Link
              onTouchStart={() => this.mobileClick('transBtn', true)}
              onTouchEnd={() => this.mobileClick('transBtn', false)}
              className={transBtn ? 'touchover' : ''}
              to={'/transfer'}
            >
              {intl.get('record_transfer_text')}
            </Link>
            <Link
              onTouchStart={() => this.mobileClick('receiveBtn', true)}
              onTouchEnd={() => this.mobileClick('receiveBtn', false)}
              className={receiveBtn ? 'touchover' : ''}
              to={'/receive'}
            >
              {intl.get('record_collect_text')}
            </Link>
          </div>
          {/* 块同步 */}
          <div className="stat-process long-bar">
            <span className="stat-text">
              <span className={nodeWaitSync ? 'color-status red' : 'color-status'}></span>
              {nodeWaitSync ? (
                <div className="red">{intl.get('account.transfer_disable')}</div>
              ) : (
                <div className="green">{intl.get('account.transferable')}</div>
              )}
            </span>
            <span className="stat-status">
              <span className="loading"></span>
              {intl.get('account.stat_process')}
              <span>
                {blockData.currentBlock}/{blockData.totalBlocks}
              </span>
            </span>
          </div>
        </div>
        {/* 公开账户 */}
        <div className="public-account account-block">
          <div className="title public">
            <span className="open">{intl.get('acnt_pub')}</span>
            <Tooltip title={intl.get('trans_taccount_tips')} placement="bottomRight">
              <span
                onTouchStart={() => this.mobileClick('toolTipPublic', true)}
                onTouchEnd={() => this.mobileClick('toolTipPublic', false)}
                className={'tooltip-icon ' + (toolTipPublic ? 'touchover' : '')}
              ></span>
            </Tooltip>
          </div>
          <div className="account-info">
            <div className={'balance info ' + (formatNumber(this.props.ztron.addressBalance).length > 12 ? 'min' : '')}>
              <span>{formatNumber(addressBalance)}</span>
              <span className="uint"> {tokenSymbol}</span>
            </div>
            <div className="address">
              {cutMiddle(address, 13, 14)}{' '}
              <span className="copy-link" title={address} onClick={copyToClipboard}>
                <img
                  src={copyImg}
                  alt="copyImg"
                  onTouchEnd={() => this.mobileClick('copyPublic', false)}
                  onTouchStart={() => this.mobileClick('copyPublic', true)}
                  className={'copyBtn ' + (copyPublic ? 'touchover' : '')}
                />
              </span>
            </div>
          </div>
          <div className="action-btns flex-between">
            <Link
              onTouchStart={() => {
                this.mobileClick('transToBtn', true);
                message.warning(intl.get('transfer_error_text9'));
              }}
              onTouchEnd={() => this.mobileClick('transToBtn', false)}
              className={transToBtn ? 'touchover' : ''}
              // to={'/transfer/mint'}
              to={'/info'}
            >
              {intl.get('account.transfer_shield')}
            </Link>
            {resourceDisable && (
              <Link className="special-link" to={'/receive/public'}>
                {intl.get('account.trans_chargetrx')}
              </Link>
            )}
          </div>
          {/* 用户资源信息 */}
          <div className="other-info long-bar high-bar">
            <span className={resourceDisable ? 'color-status red' : 'color-status'}></span>
            {/* 余额，能量，带宽 */}
            <span className="balance">
              <div>
                <span className={trxState}>{formatNumber(addressTrx)} </span>
                <span className="uint">TRX</span>
              </div>
              {resourceDisable ? (
                <div className="red">{intl.get('account.trans_taccount_status_disable')}</div>
              ) : (
                <div className="green">{intl.get('account.trans_taccount_status_ready')}</div>
              )}
            </span>
            <div className="energy-band">
              <span className="energy">
                <p className={energyState}>{formatNumber(addressEnergy, 0)} </p>
                <p>{intl.get('account.energy')}</p>
              </span>
              <span className="bandwidth">
                <p className={bandwidthState}>{formatNumber(addressBandwidth, 0)} </p>
                <p>{intl.get('account.bandwidth')}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountInfo;
