import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import isMobile from 'ismobilejs';
import axios from 'axios';
import Config from '../config';
import { isTronLink } from '../utils/iTron';

import Header from './Header';
import GuideHeader from './guide/header';
import Footer from './Footer';

import '../assets/css/guide.scss';

import feature1 from '../assets/images/web/safety.svg';
import feature2 from '../assets/images/web/instant-transaction.svg';
import feature3 from '../assets/images/web/privacy.svg';
import feature4 from '../assets/images/web/interactive-process.svg';
import mainPic from '../assets/images/web/iPhoneFront.png';
import dapp from '../assets/images/web/iPhoneFront2.png';
import mainPic_m from '../assets/images/web/iPhoneFront.png';
import dapp_m from '../assets/images/web/miPhoneFront2.png';
import Google_Play from '../assets/images/web/Google_Play.svg';
import more from '../assets/images/arrow.svg';
import android from '../assets/images/web/android.svg';
import person from '../assets/images/person.svg';
import tronPerson from '../assets/images/tronPerson.svg';

@inject('ztron')
@observer
class Guide extends React.Component {
  constructor(props) {
    super(props);
    this.unmount = false;
    this.state = {
      iosDownLink: '',
      AndroidDownLinkByGooglePlay: Config.tronlinkGooglePlay,
      AndroidDownLinkByLine1: '',
      AndroidDownLinkByLine2: '',
      lang: window.localStorage.getItem('lang') || intl.options.currentLocale,
      loadingStatus: false, //this.props.loadingStatus,
      donwloadStatus: false,
      md5: ''
    };
  }

  componentDidMount() {
    this.unmount = false;
    this.props.ztron.setLocationStr('home');
    // 初始化时 loading 1500ms, 预留判断时间
    setTimeout(() => {
      if (this.unmount) return;

      this.setState({ loadingStatus: false });
    }, 1500);
    // 请求 tronlink android 下载路径
    // axios.get(Config.getLatestTronlinkAPK).then(res => {
    //   if (res.data.code === 0) {
    //     this.setState({
    //       iosDownLink: res.data.data.testflight,
    //       AndroidDownLinkByLine1: res.data.data.china_url,
    //       AndroidDownLinkByLine2: res.data.data.url,
    //       md5: res.data.data.md5
    //     });
    //   }
    // });
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  setLanguage = lang => {
    window.localStorage.setItem('lang', lang);
    window.location.search = `?lang=${lang}`;
  };

  toggleHover = action => {
    if (action === 'show') {
      this.setState({ donwloadStatus: true });
    } else if (action === 'hide') {
      this.setState({ donwloadStatus: false });
    } else if (action === 'toggle') {
      this.setState({ donwloadStatus: !this.state.donwloadStatus });
    }
  };

  getLogo = symbol => {
    let icon = '';
    try {
      icon = require(`../assets/images/icons/${symbol.toLowerCase()}White.svg`);
    } catch (error) {
      icon = require(`../assets/images/icons/trxWhite.svg`);
    }
    return icon;
  };

  onChangeToken = tokenSymbol => {
    this.props.ztron.setTokenSymbol(tokenSymbol);
  };

  render() {
    const {
      AndroidDownLinkByGooglePlay,
      AndroidDownLinkByLine1,
      AndroidDownLinkByLine2,
      loadingStatus,
      donwloadStatus,
      md5
    } = this.state;
    const windowWidth = document.body.clientWidth || document.documentElement.clientWidth;

    const isLogin = isTronLink() && isMobile(window.navigator).any;

    let mainPicImg = mainPic;
    let dappImg = dapp;
    if (isMobile(window.navigator).any) {
      mainPicImg = mainPic_m;
      dappImg = dapp_m;
    }
    return (
      <div className="product">
        {loadingStatus ? (
          <aside></aside>
        ) : (
          <div>
            <section className="darkBlue">
              {isMobile(window.navigator).any || windowWidth < 1000 ? <Header isLogin={isLogin} /> : <GuideHeader />}
              <div className="product-main minWidth">
                <div className="left">
                  <h2 className="main-title">{intl.get('index_slogan')}</h2>
                  <div className="second-title">{intl.get('index_subslogan_des')}</div>
                  {isMobile(window.navigator).any || windowWidth < 1000 ? (
                    isLogin ? (
                      <a className="product-start-btn" href="#/info" onClick={() => this.onChangeToken('USDT')}>
                        {intl.get('index_section_feature_title2')}
                      </a>
                    ) : (
                      <a href="#startNow" className="product-start-btn">
                        {intl.get('index_section_feature_title2')}
                      </a>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <img src={mainPicImg} alt="main picture" />
              </div>
            </section>
            {isLogin && (
              <section className="tokenlist">
                <div className="tac">
                  <h3 className="title">
                    {intl.get('index_section_tokenlist_title')}
                    <span className="bgBlock"></span>
                  </h3>
                </div>
                <ul>
                  {Object.values(Config.tokenMap).map(
                    token => {
                      if (token.open) {
                        return (
                          <li key={token.symbol}>
                            <span className="tokenName">
                              <img src={this.getLogo(token.symbol)} alt="" className="logo" />
                              {token.symbol}
                            </span>
                            <Link
                              className="btn trans-btn"
                              to={'/info'}
                              onClick={() => this.onChangeToken(token.symbol)}
                            >
                              {intl.get('index_section_tokenlist_btexchange')}
                            </Link>
                          </li>
                        );
                      }
                    }
                    //    <li key={token.symbol}>
                    //    <span className="tokenName">
                    //      <img src={this.getLogo(token.symbol)} alt="" className="logo" />
                    //      {token.symbol}
                    //    </span>
                    //    <Link className="btn not-open-btn" to={'/'} disabled>
                    //      {intl.get('index_section_tokenlist_btnotsupport')}
                    //    </Link>
                    //  </li>
                  )}
                </ul>
              </section>
            )}
            {isMobile(window.navigator).any || windowWidth < 1000 ? (
              <section className="features minWidth">
                <div className="based-network">
                  <div>
                    <h3 className="title">
                      {intl.get('index_section_basetron_title')}
                      <span className="bgBlock"></span>
                    </h3>
                  </div>
                  <div className="based-content">
                    <p>{intl.get('index_section_basetron_des')}</p>
                    <img src={person} />
                  </div>
                  {/* <div className="btn blue manual">
                    <a href={Config.helpLink} target="_help">
                      {intl.get('version.guide')}
                    </a>
                  </div> */}
                </div>
                <div>
                  <h3 className="title">
                    {intl.get('product_feature_title')}
                    <span className="bgBlock"></span>
                  </h3>
                </div>
                <article>
                  <div className="flexBox">
                    <img src={feature1} alt="featureImg" />
                    <div className="text">
                      <h4>{intl.get('product_feature_title1')}</h4>
                      <p>{intl.get('product_feature_text1')}</p>
                    </div>
                  </div>
                </article>
                <Divider className="divider" type="vertical"></Divider>
                <article className="right">
                  <div className="flexBox">
                    <img src={feature2} alt="featureImg" />
                    <div className="text">
                      <h4>{intl.get('product_feature_title2')}</h4>
                      <p>{intl.get('product_feature_text2')}</p>
                    </div>
                  </div>
                </article>
                <article>
                  <div className="flexBox">
                    <img src={feature3} alt="featureImg" className="imgAdjust" />
                    <div className="text">
                      <h4>{intl.get('product_feature_title3')}</h4>
                      <p>{intl.get('product_feature_text3')}</p>
                    </div>
                  </div>
                </article>
                <Divider className="divider" type="vertical"></Divider>
                <article className="right">
                  <div className="flexBox">
                    <img src={feature4} alt="featureImg" />
                    <div className="text">
                      <h4>{intl.get('product_feature_title4')}</h4>
                      <p>{intl.get('product_feature_text4')}</p>
                    </div>
                  </div>
                </article>
                <div>
                  <h3 className="title mt30">
                    {intl.get('index_section_faq_title')}
                    <span className="bgBlock"></span>
                  </h3>
                </div>

                <article className="right common-use">
                  <div className="flexBox">
                    <div className="text">
                      <h4>{intl.get('index_section_faq_q1')}</h4>
                      <p>{intl.get('index_section_faq_a1')}</p>
                    </div>
                  </div>
                </article>
                <Divider className="divider" type="vertical"></Divider>
                <article className="right common-use">
                  <div className="flexBox">
                    <div className="text">
                      <h4>{intl.get('index_section_faq_q2')}</h4>
                      <p>{intl.get('index_section_faq_a2')}</p>
                    </div>
                  </div>
                </article>
                <article className="right common-use">
                  <div className="flexBox">
                    <div className="text">
                      <h4>{intl.get('index_section_faq_q3')}</h4>
                      <p>{intl.get('index_section_faq_a3')}</p>
                    </div>
                  </div>
                </article>
                <Divider className="divider" type="vertical"></Divider>
                <article className="right common-use">
                  <div className="flexBox">
                    <div className="text">
                      <h4>{intl.get('index_section_faq_q4')}</h4>
                      <p>{intl.get('index_section_faq_a4')}</p>
                    </div>
                  </div>
                </article>
              </section>
            ) : (
              <>
                <section className="features minWidth">
                  <h3 className="title">
                    {intl.get('product_feature_title')}
                    <span className="bgBlock"></span>
                  </h3>
                </section>
                <section className="feature-power">
                  <section className="power-width minWidth">
                    <section>
                      <section className="power-title">{intl.get('product_feature_title1')}</section>
                      <section className="power-content">{intl.get('product_feature_text1')}</section>
                    </section>
                    <section>
                      <section className="power-title">{intl.get('product_feature_title2')}</section>
                      <section className="power-content">{intl.get('product_feature_text2')}</section>
                    </section>
                    <section>
                      <section className="power-title">{intl.get('product_feature_title3')}</section>
                      <section className="power-content">{intl.get('product_feature_text3')}</section>
                    </section>
                    <section>
                      <section className="power-title">{intl.get('product_feature_title4')}</section>
                      <section className="power-content">{intl.get('product_feature_text4')}</section>
                    </section>
                  </section>
                </section>
                <section className="tron-power minWidth">
                  <section className="tron-power-content">
                    <h3 className="title">
                      {intl.get('index_section_basetron_title')}
                      <span className="bgBlock"></span>
                    </h3>
                    <p>{intl.get('index_section_basetron_des')}</p>
                    {/* <div className="btn blue manual mt30">
                      <a href={Config.helpLink} target="_help">
                        {intl.get('version.guide')}
                      </a>
                    </div> */}
                  </section>
                  <img src={tronPerson} />
                </section>
                <section className="features minWidth feature-faq">
                  <div>
                    <h3 className="title">
                      {intl.get('index_section_faq_title')}
                      <span className="bgBlock"></span>
                    </h3>
                  </div>
                  <article className="right common-use">
                    <div>
                      <div className="text">
                        <h4>{intl.get('index_section_faq_q1')}</h4>
                        <p>{intl.get('index_section_faq_a1')}</p>
                      </div>
                    </div>
                  </article>
                  <article className="right common-use">
                    <div>
                      <div className="text">
                        <h4>{intl.get('index_section_faq_q2')}</h4>
                        <p>{intl.get('index_section_faq_a2')}</p>
                      </div>
                    </div>
                  </article>
                  <article className="right common-use">
                    <div>
                      <div className="text">
                        <h4>{intl.get('index_section_faq_q3')}</h4>
                        <p>{intl.get('index_section_faq_a3')}</p>
                      </div>
                    </div>
                  </article>
                  <article className="right common-use">
                    <div>
                      <div className="text">
                        <h4>{intl.get('index_section_faq_q4')}</h4>
                        <p>{intl.get('index_section_faq_a4')}</p>
                      </div>
                    </div>
                  </article>
                  <article className="right common-use">
                    <div>
                      <div className="text">
                        <h4>{intl.get('index_section_faq_q5')}</h4>
                        <p>{intl.get('index_section_faq_a5')}</p>
                      </div>
                    </div>
                  </article>
                </section>
              </>
            )}
            {!isLogin && (
              <section className="startNow minWidth hide" id="startNow">
                <h3 className="title">
                  {intl.get('product_start_title')}
                  <span className="bgBlock"></span>
                </h3>
                <div className="steps">
                  <article>
                    <div className="artTitle">{intl.get('product_start_title')}</div>
                    <h4 className="step">
                      {intl.get('product_step1_text')}
                      {/* <span className="mobile">({intl.get("product_install_text")})</span> */}
                    </h4>
                    <h4 className="step">{intl.get('product_step2_text')}</h4>
                    <div className="btns">
                      {/* <div
                        className="downloadBtn"
                        onClick={e => this.toggleHover('show')}
                        onMouseEnter={e => this.toggleHover('show')}
                        onMouseLeave={e => this.toggleHover('hide')}
                      >
                        {intl.get('guide_download_android')}
                        {donwloadStatus ? (
                          <section className="dropdown-content">
                            <div className="dropdown-box">
                              <div className="dropdown-item">
                                <a href={AndroidDownLinkByGooglePlay} target="_blank">
                                  <div className="drop-flex">
                                    <span>
                                      <img src={Google_Play} className="image-google" />
                                      <span className="download-name">{intl.get('guide_download_googleplay')}</span>
                                    </span>
                                    <img src={more} className="image-op image-more" />
                                  </div>
                                </a>
                              </div>
                              <div className="dropdown-item Android-download">
                                <a className="drop-flex android-apk-url">
                                  <span>
                                    <img src={android} className="image-android" />
                                    <span className="download-name">{intl.get('guide_download_apk')}</span>
                                  </span>
                                </a>
                                <p className="md5">
                                  md5:<span className="md5Code">{md5}</span>
                                </p>
                              </div>
                              <div className="Android-download-line">
                                <p>{intl.get('guide_download_DownloadLine')}</p>
                                <div className="download-line">
                                  {/* <a className="china-url" target="_blank" href={AndroidDownLinkByLine1}>
                                    {intl.get('guide_download_DownloadLine1')}
                                  </a> */}
                      {/* <a className="us-url" target="_blank" href={AndroidDownLinkByLine2}>
                                    {intl.get('guide_download_DownloadLine2')}
                                  </a>*/}
                      {/*</div>*/}
                      {/* </div>*/}
                      {/*</div>*/}
                      {/*</section>*/}
                      {/*) : null}>*/}
                      {/*</div> */}
                      {/* <a className="downloadBtn" href={iosDownLink} target="_blank">{intl.get("guide_download_ios")}</a> */}
                    </div>
                  </article>
                  <div className="right">
                    <img src={dappImg} alt="dappImg" className="dappImg" />
                  </div>
                </div>
              </section>
            )}
            <Footer />
          </div>
        )}
      </div>
    );
  }
}

export default Guide;
