import BigNumber from 'bignumber.js';
const env = process.env.REACT_APP_ENV;

export const TOKEN_TYPE = {
  TRC20: 0,
  TRX: 1,
  TRC10: 2
};

export const genTokenInfo = tokenInfo => {
  const decimals = tokenInfo.decimals === undefined ? 6 : tokenInfo.decimals;
  const precision = BigNumber(10).pow(decimals);

  const defaultToken = {
    symbol: '',
    address: '',
    tokenId: 0,
    decimals,
    precision,
    shieldContract: '',
    scalingFactor: 1,
    type: TOKEN_TYPE.TRC20, // 0-trc20 1-TRX 2-trc10
    open: true
  };

  return Object.assign(defaultToken, tokenInfo);
};

const Config = {
  trongrid: {
    host: 'https://api.trongrid.io',
    header: 'TRON-PRO-API-KEY',
    key: '0ea18b5a-0bc0-4024-a561-585d46cac38e'
  },
  USDT: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
  shieldContractAddress: 'TQEuSEVRk1GtfExm5q9T8a1w84GvgQJ13V',
  poly: 'TYsT2bhJ8BAP1TKcdE6MW1zCqn9WiXhMNE',
  tokenMap: {
    USDT: genTokenInfo({
      symbol: 'USDT',
      address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
      shieldContract: 'TQEuSEVRk1GtfExm5q9T8a1w84GvgQJ13V'
    }),
    USDJ: genTokenInfo({
      symbol: 'USDJ',
      address: 'TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT',
      decimals: 18,
      shieldContract: 'TN2CytJakVXNagWcTu3BKtaHQjrVEvjGkb',
      scalingFactor: 1e9
    }),
    TRX: genTokenInfo({
      symbol: 'TRX',
      shieldContract: 'TWukE3P7LDPgaHX9GcphGZfsikoqzuxbey',
      type: TOKEN_TYPE.TRX
    }),
    BTTOLD: genTokenInfo({
      symbol: 'BTTOLD',
      tokenId: 1002000,
      shieldContract: 'TBS1DnnmEi6NVgpCwboDre14EYio2vZgpF',
      type: TOKEN_TYPE.TRC10
    }),
    JST: genTokenInfo({
      symbol: 'JST',
      address: 'TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9',
      decimals: 18,
      shieldContract: 'TWCdZbTc9ZMxNN2JS2xdYMCBkrvVZxHdNW',
      scalingFactor: 1e9
    }),
    WIN: genTokenInfo({
      symbol: 'WIN',
      address: '',
      shieldContract: '',
      open: false
    })
  },
  localStorage: {
    selectedToken: 'selectedToken'
  },
  defaultAddress: 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb',
  TOKEN_TYPE,
  feeLimit: 300 * 1e6,
  trxCost: 200,
  bandwidthCost: 3300,
  energyCost: 450000,
  newbeeKey: 'newbee',
  usdtAppVersion: '3.7.4',
  tokenAppVersion: '3.9.1',
  getLatestTronlinkAPK: 'https://list.tronlink.org/api/v1/wallet/getLatestAPK',
  tronlinkGooglePlay: 'https://play.google.com/store/apps/details?id=com.tronlinkpro.wallet',
  githubLink: 'https://github.com/tronprotocol/tips/blob/master/tip-135.md',
  APILink:
    'https://github.com/tronprotocol/documentation-en/blob/master/docs/mechanism-algorithm/shielded-TRC20-contract.md',
  telegramLink: 'https://t.me/zkWrapperGuide',
  twitterLink: 'https://twitter.com/zkWrapper',
  tronlink: 'https://www.tronlink.org/',
  transaction: 'https://tronscan.io/#/transaction/',
  homeLink: '#/home',
  helpLink: '#/help',
  createGuideLink: '#/createguide'
};

let devConfig = {};
if (env === 'test' || env === 'testMain') {
  devConfig = require(`./config.${env}.js`).default;
}

export default Object.assign(Config, devConfig);
