import React from 'react';
import isMobile from 'ismobilejs';

import GuidePage from '../components/Guide';
import { inject, observer } from 'mobx-react';

@inject('ztron')
@observer
class Home extends React.Component {
  render() {
    return <div>{isMobile(window.navigator).any ? <GuidePage loadingStatus /> : <GuidePage />}</div>;
  }
}

export default Home;
