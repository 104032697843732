import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

import Config from '../config';
import Header from './Header';
import AccountInfo from './AccountInfo';
import Records from './Records';
import Footer from './Footer';

import '../assets/css/home.scss';

@inject('ztron')
@observer
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.ztron.setLocationStr('info');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="wrapper-home">
        <Header isInfo />
        <main className="main-content">
          <AccountInfo />
          <Records />
        </main>
        <Footer />
      </div>
    );
  }
}

export default Home;
