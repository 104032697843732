import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { copyToClipboard } from '../utils/helper';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import Config from '../config';
import '../assets/css/collect.scss';

import copyImg from '../assets/images/copy.svg';
import notlogin from '../assets/images/account.svg';

@inject('ztron')
@observer
class Receive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publicAddress: false,
      mobileCopy: false
    };
  }

  componentDidMount() {
    const hash = window.location.hash;
    const type = hash.split('/');

    if (type[2] === 'public') {
      this.setState({ publicAddress: true });
    }
  }

  downloadQRCode = e => {
    // 下载二维码
    const canvas = document.getElementsByClassName('qrcode-box')[0].childNodes[0];
    const img = canvas.toDataURL('image/png');
    e.target.href = img;
  };

  mobileCopyClick = mobileCopy => {
    this.setState({ mobileCopy });
  };

  render() {
    const { publicAddress, mobileCopy } = this.state;
    const address = publicAddress ? this.props.ztron.address : this.props.ztron.privacyAddress;
    const tokenArr = [];
    Object.values(Config.tokenMap).map(token => {
      if (token.open) {
        tokenArr.push(token.symbol);
      }
    });
    const tokens = tokenArr.join(', ');
    return (
      <div className="collect transfer-component">
        <div className="title">
          <Link to={'/info'}>{intl.get('transfer.return')}</Link>
          {publicAddress ? intl.get('receive.public_title') : intl.get('receive.privacy_title')}
        </div>
        {/* 是否有匿名账户 */}
        {
          // priConnected ?
          <div>
            <div className="help-block">
              {publicAddress ? intl.getHTML('receive.public') : intl.getHTML('receive.privacy', { tokens })}
              <div></div>

              <div className="address">
                <div className="qrcode-box">
                  <QRCode value={address} bgColor="#fff" fgColor="#000" level="L" size={90} />
                </div>
                {/* <div>
                  <button>
                    <a href="#" download="qrcode.png" onClick={this.downloadQRCode}>
                      {intl.get('receive.download')}
                    </a>
                  </button>
                </div> */}
              </div>
            </div>
            <div
              className="copy"
              id="priText"
              title={address}
              onClick={copyToClipboard}
              style={{ display: 'inline-block', position: 'relative' }}
            >
              {address}{' '}
              <img
                src={copyImg}
                alt="copyImg"
                onTouchEnd={() => this.mobileCopyClick(false)}
                onTouchStart={() => this.mobileCopyClick(true)}
                className={'copyBtn ' + (mobileCopy ? 'touchover' : '')}
              />
            </div>
            {!publicAddress && <div className="tip">{intl.get('receive.tip')}</div>}
          </div>
          // :
          // <div className="no-login">
          //   <img src={notlogin} alt="notlogin"/>
          //   <p>{intl.get("collect_notLogin_text")}</p>
          //   <p className="tipPlease">{intl.get("collect_please_text")}</p>
          //   <p className="tipLink"><a href={Config.createGuideLink} target="_blank">{intl.get("collect_guide_text")}</a></p>
          // </div>
        }
      </div>
    );
  }
}

export default Receive;
