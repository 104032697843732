import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import isMobile from 'ismobilejs';

import Header from './Header';
import GuideHeader from './guide/header';
import Footer from './Footer';

import '../assets/css/help.scss';

import guide1 from '../assets/images/guide/pc1.png';
import guide2 from '../assets/images/guide/pc2.png';

import guide1_en from '../assets/images/guide/pc1_en.png';
import guide2_en from '../assets/images/guide/pc2_en.png';

import mobileGuide1 from '../assets/images/guide/c1.png';
import mobileGuide2 from '../assets/images/guide/c2.png';

import mobileGuide1_en from '../assets/images/guide/c1_en.png';
import mobileGuide2_en from '../assets/images/guide/c2_en.png';

import bottomImg from '../assets/images/web/kv_bg.png';

@inject('ztron')
@observer
class CreateGuide extends React.Component {
  constructor(props) {
    super(props);
    this.unmount = false;
    this.state = {
      iosDownLink: '',
      AndroidDownLink: '',
      lang: window.localStorage.getItem('lang') || intl.options.currentLocale,
      mobileImgs: [mobileGuide1, mobileGuide2],
      mobileImgs_en: [mobileGuide1_en, mobileGuide2_en],
      PCImgs: [guide1, guide2],
      PCImgs_en: [guide1_en, guide2_en]
    };
  }

  componentDidMount() {
    this.unmount = false;
    this.props.ztron.setLocationStr('createguide');
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  setLanguage = lang => {
    window.localStorage.setItem('lang', lang);
    window.location.search = `?lang=${lang}`;
  };

  render() {
    let { mobileImgs, mobileImgs_en, PCImgs, PCImgs_en, lang } = this.state;
    let ImgArr = [];
    if (isMobile(window.navigator).any) {
      if (this.state.lang === 'en-US') {
        ImgArr = mobileImgs_en;
      } else {
        ImgArr = mobileImgs;
      }
    } else {
      if (this.state.lang === 'en-US') {
        ImgArr = PCImgs_en;
      } else {
        ImgArr = PCImgs;
      }
    }
    let windowWidth = document.body.clientWidth || document.documentElement.clientWidth;
    return (
      <div className="helpCenter">
        {isMobile(window.navigator).any || windowWidth < 1000 ? <Header /> : <GuideHeader />}
        <div className="helpContent minWidth createGuideH4">
          <h3 className={'title ' + (lang === 'en-US' ? 'fz18' : 'fz24')}>{intl.get('help_create_title')}</h3>
          <section className="manual">
            <li className="single">
              <p className="topNote">{intl.get('help_create_1')}</p>
            </li>
            <li>
              <h4>{intl.get('help_create_2')}</h4>
              <img src={ImgArr[0]} className="img0" />
            </li>
            <li>
              <h4>{intl.get('help_create_3')}</h4>
              <img src={ImgArr[1]} className="img1" />
            </li>
            <li>
              <h4>{intl.get('help_create_4')}</h4>
            </li>
            <li>
              <h4>{intl.get('help_create_5')}</h4>
            </li>
            <li>
              <h4>{intl.get('help_create_6')}</h4>
            </li>
          </section>
        </div>
        <img src={bottomImg} className="topImg" />
        <img src={bottomImg} className="bottomImg createPage" />
        <Footer />
      </div>
    );
  }
}

export default CreateGuide;
