import React from 'react';
import { Modal } from 'antd';
import './style.scss';
import { BUTTON_BG } from './config.js';
import axios from 'axiosv1';
import isMobile from 'ismobilejs';

export class NoServiceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hasAgree: getHasAgree()
    };
  }
  componentDidMount() {
    if (!this.state.hasAgree) {
      getTipStatus().then(res => {
        if (res) {
          this.setState({
            visible: res
          });
        }
      });
    }
  }
  onClick = () => {
    this.setState({
      visible: false,
      hasAgree: true
    });
    setHasAgree();
  };
  render() {
    const { visible, hasAgree } = this.state;
    const { dark } = this.props;
    return (
      <Modal
        closable={false}
        width={mobile ? 'calc(100vw - 40px)' : '460px'}
        visible={!hasAgree && visible}
        wrapClassName={`no-service-modal${dark ? ' dark' : ''}`}
        footer={null}
        zIndex={99999}
      >
        <div className="content">
          <div className="icon"></div>
          <p className="text">
            無法向中國大陸用戶提供產品或服務，請立即停止使用。點擊 “我同意”
            即表示您確認並承諾，您不是中國大陸用戶或未在中國大陸使用本服務。
          </p>
          <p className="text">
            Mainland China users are not eligible to use the products or services offered by current platform. By
            clicking "I Agree" you represent and warrant that you are not a Mainland China user, nor accessing the
            platform from Mainland China.
          </p>
          <div onClick={this.onClick} className="btn" style={{ background: BUTTON_BG }}>
            我同意 / I Agree
          </div>
        </div>
      </Modal>
    );
  }
}

const instance = axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  }
});
const mobile = isMobile(window.navigator).any;
const getTipStatus = async () => {
  try {
    await instance.get('https://rioj.ablesdxd.link/?time=' + Date.now());
  } catch (error) {
    if (error.code) {
      return true;
    }
  }
};

const getHasAgree = () => {
  return window.localStorage.getItem('NoService:hasAgree');
};
const setHasAgree = () => {
  window.localStorage.setItem('NoService:hasAgree', 'true');
};
