import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import { Menu, Select, Drawer, Modal } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

import Config from '../config';
import { isTronLink } from '../utils/iTron';
import { checkVersion } from '../utils/helper';

const { SubMenu } = Menu;
const { Option } = Select;

import fullLogo from '../assets/images/main-logo.svg';
import Logo from '../assets/images/favicon.svg';
import closeImg from '../assets/images/close.svg';
import menuIcon1 from '../assets/images/menuIcon1.svg';
import menuIcon2 from '../assets/images/menuIcon2.svg';
import menuIcon3 from '../assets/images/menuIcon3.svg';
import menuIcon4 from '../assets/images/menuIcon4.svg';
import menuIcon5 from '../assets/images/menuIcon5.svg';
import { NoServiceModal } from '../components/no-service-modal/index.jsx';

@inject('ztron')
@observer
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: window.localStorage.getItem('lang') || intl.options.currentLocale,
      drawVisible: false,
      priVisible: false,
      versionPop: false
    };
  }

  componentDidMount() {
    const { isInfo } = this.props;
    if (!this.props.ztron.zTron || !this.props.ztron.priConnected) {
      this.props.ztron.initTronLinkWallet(
        () => {},
        () => {
          if (isTronLink()) {
            if (!this.state.versionPop && isInfo) {
              this.setState({ priVisible: true });
            }
          }
        }
      );
    }
    if (isInfo) {
      this.setState({ versionPop: checkVersion(Config.tokenAppVersion) });
    }
  }

  handleCancel = e => {
    this.setState({
      priVisible: false
    });
  };

  handleVersionCancel = e => {
    this.setState({
      priVisible: false
    });
  };

  showDrawer = () => {
    this.setState({ drawVisible: true });
  };

  onClose = () => {
    this.setState({ drawVisible: false });
  };

  loadUrl = path => {
    this.setState({ drawVisible: false });
    location.replace(`/?lang=${this.state.lang}#/${path}`);
  };

  setLanguage = lang => {
    this.props.ztron.setLanguage(lang);
  };

  openLink = key => {
    if (key === 'api') {
      window.open(Config.APILink);
    } else if (key === 'github') {
      window.open(Config.githubLink);
    } else if (key === 'startNow') {
      if (this.props.ztron.priConnected && this.props.ztron.locationStr === 'home') {
        this.setState({ drawVisible: false });
        return;
      }
      window.location.hash = '#startNow';
      this.setState({ drawVisible: false });
    }
  };

  onChangeToken = tokenSymbol => {
    this.props.ztron.setTokenSymbol(tokenSymbol);
  };

  getLogo = symbol => {
    let icon = '';
    try {
      icon = require(`../assets/images/icons/${symbol.toLowerCase()}.svg`);
    } catch (error) {
      icon = require(`../assets/images/icons/trx.svg`);
    }
    return icon;
  };

  render() {
    const { lang } = this.state;
    const { isLogin } = this.props;
    const { locationStr, tokenSymbol } = this.props.ztron;
    return (
      <div className="header-h">
        <div className="logo">
          <a href={Config.homeLink}>
            {locationStr === 'info' ? <img src={Logo} alt="zkWrapper" /> : <img src={fullLogo} alt="zkWrapper" />}
          </a>
        </div>

        {locationStr === 'info' && (
          <Select
            placeholder={Config.tokenMap['USDT'].symbol}
            className={'token-select'}
            value={tokenSymbol ? tokenSymbol : undefined}
            onChange={this.onChangeToken}
            dropdownClassName="token-select-list"
          >
            {Object.values(Config.tokenMap).map(
              token =>
                token.open && (
                  <Option key={token.symbol} value={token.symbol}>
                    <img src={this.getLogo(token.symbol)} />
                    {token.symbolNew || token.symbol}
                  </Option>
                )
            )}
          </Select>
        )}

        <div
          className="menu"
          onClick={() => {
            this.showDrawer();
          }}
        >
          <UnorderedListOutlined />
        </div>
        <Drawer
          title={null}
          placement="right"
          onClose={this.onClose}
          visible={this.state.drawVisible}
          closeIcon={<img src={closeImg} alt="" className="menu-svg-close" />}
          className="menu-dr"
        >
          <div className="dr-content">
            <img src={Logo} alt="" className="dr-logo" />
            <Menu onClick={this.handleClick} style={{ width: '100%' }} defaultOpenKeys={['1']} mode="inline">
              <Menu.Item
                key="m1"
                icon={<img src={menuIcon5} alt="" className="menu-svg" />}
                className={'menu-m ' + (locationStr === 'home' ? 'menu-active' : '')}
              >
                <a href="#/home" className="link-a" onClick={this.onClose}>
                  {intl.get('home')}
                </a>
              </Menu.Item>
              <Menu.Item
                key="m2"
                icon={<img src={menuIcon1} alt="" className="menu-svg" />}
                className={'menu-m ' + (locationStr === 'info' ? 'menu-active' : '')}
              >
                <a href={isLogin ? `/?lang=${lang}#/info` : '#startNow'} className="link-a" onClick={this.onClose}>
                  {intl.get('menu_transaction')}
                </a>
              </Menu.Item>
              {/* <SubMenu
                key="sub1"
                title={
                  <span>
                    <img src={menuIcon2} alt="" className="menu-svg" />
                    <span>{intl.get('menu_develop')}</span>
                  </span>
                }
                className={'menu-m ' + (locationStr === 'developer' ? 'menu-active' : '')}
              >
                <Menu.Item key="1" className="menu-s">
                  <a href={Config.APILink} className="link-a" onClick={this.onClose}>
                    {intl.get('menu_develop_api')}
                  </a>
                </Menu.Item>
                <Menu.Item key="2" className="menu-s">
                  <a href={Config.githubLink} className="link-a" onClick={this.onClose}>
                    {intl.get('menu_develop_github')}
                  </a>
                </Menu.Item>
              </SubMenu> */}
              {/* <SubMenu
                key="sub4"
                className={'menu-m ' + (locationStr === 'help' || locationStr === 'createguide' ? 'menu-active' : '')}
                title={
                  <span>
                    <img src={menuIcon3} alt="" className="menu-svg" />
                    <span>{intl.get('menu_help')}</span>
                  </span>
                }
              >
                <Menu.Item key="3" className={'menu-s ' + (locationStr === 'help' ? 'menu-active' : '')}>
                  <a href={Config.helpLink} className="link-a" onClick={this.onClose}>
                    {intl.get('menu_help_manual')}
                  </a>
                </Menu.Item>
                <Menu.Item key="4" className={'menu-s ' + (locationStr === 'createguide' ? 'menu-active' : '')}>
                  <a href={Config.createGuideLink} className="link-a" onClick={this.onClose}>
                    {intl.get('menu_help_creation')}
                  </a>
                </Menu.Item>
              </SubMenu> */}
              {/* <Menu.Item key="5" icon={<img src={menuIcon4} alt="" className="menu-svg" />} className="menu-m menu-lg">
                <span
                  className={'zh-text ' + (lang === 'zh-CN' ? 'lg-active' : '')}
                  onClick={() => {
                    this.setLanguage('zh-CN');
                  }}
                >
                  中文
                </span>
                <span className="slice-lg"> / </span>
                <span
                  className={'en-text ' + (lang === 'en-US' ? 'lg-active' : '')}
                  onClick={() => {
                    this.setLanguage('en-US');
                  }}
                >
                  English
                </span>
              </Menu.Item> */}
            </Menu>
          </div>
        </Drawer>

        <Modal
          title={null}
          visible={this.state.priVisible}
          footer={null}
          closable={false}
          maskClosable={false}
          onCancel={this.handleCancel}
          bodyStyle={{
            background: '#363947',
            borderRadius: '12px',
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.50)'
          }}
          className="home-modal"
        >
          <div className="modal-body-title">{intl.getHTML('modal_title')}</div>
          <div className="body-text">{intl.getHTML('modal_body_p1')}</div>
          {/* <div style={{ marginTop: '20px' }} className="body-text">
            {intl.get('modal_body_p2')}
            <a href={Config.createGuideLink} target="_createguide">
              {intl.get('modal_body_link')}
            </a>
          </div> */}
          {/* <div className="modal-footer">
            <span className="footer-btn">
              <a href={Config.APILink} target="_api">
                {intl.getHTML('modal_foot_btn1')}
              </a>
            </span>
            <span className="footer-btn">
              <a href={Config.helpLink} target="_help">
                {intl.getHTML('modal_foot_btn2')}
              </a>
            </span>
          </div> */}
        </Modal>
        <Modal
          title={null}
          visible={this.state.versionPop}
          footer={null}
          closable={false}
          maskClosable={false}
          onCancel={this.handleVersionCancel}
          width={315}
          bodyStyle={{
            background: '#363947',
            borderRadius: '12px',
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.50)'
          }}
          className="version-modal"
        >
          <div className="version">
            <div className="title">{intl.get('version.title')}</div>
            <div className="content">{intl.get('version.content')}</div>
            <div className="btns">
              <button className="guide">
                <a href={Config.helpLink} target="_help">
                  {intl.get('version.guide')}
                </a>
              </button>
            </div>
          </div>
        </Modal>
        <NoServiceModal dark={true} />
      </div>
    );
  }
}

export default Header;
