import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import BigNumber from 'bignumber.js';
import { LeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Input, Modal, Select, Steps, Spin } from 'antd';
import { Link } from 'react-router-dom';
import Config from '../config';
import { cutMiddle, formatNumber } from '../utils/helper';
import { approve, transfer } from '../utils';
import { isInvalidAddress, DECLINED_BY_USER, PUBLIC_ADDRESS, PRIVACY_ADDRESS, ERROR_ADDRESS } from '../utils/zTronWeb';
import { iScanQRCode } from '../utils/iTron';

import Step1 from '../assets/images/step1.svg';
import Step2 from '../assets/images/step2.svg';
import QRCode from '../assets/images/qrcode.svg';

import '../assets/css/transfer.scss';

const { Step } = Steps;
const { Option } = Select;

@inject('ztron')
@observer
class Transfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepVisible: false,
      txidVisible: false,
      privacySend: false,
      fromAccount: '',
      toAccount: '',
      sendAmount: '',
      needApprove: false,
      transferableAmount: '--',
      singleTransferableAmount: '--',
      submitEnabled: false, // 提交状态
      errorVisible: false,
      errorMsg: '',
      txid: '',
      spinning: false,
      energyCost: Config.energyCost,
      bandwidthCost: Config.bandwidthCost,
      inputError0: false, // summary error
      inputError1: false, // from account error
      inputError2: false, // receive account error
      inputError3: false // transfer amount error
    };
  }

  componentDidMount() {
    this.props.ztron.setData('transferComponent', this);

    if (this.props.ztron.priConnected) {
      this.setDefaultAddress();
    } else {
      this.props.ztron.initTronLinkWallet(() => {
        this.setDefaultAddress();
      });
    }
  }

  componentWillUnmount() {
    this.props.ztron.setData('transferComponent', {});
  }

  setDefaultAddress = () => {
    if (this.state.fromAccount) return;

    const hash = window.location.hash;
    const type = hash.split('/');

    if (type[2] === 'mint') {
      this.setState({ fromAccount: this.props.ztron.address });
    } else {
      this.setState({ fromAccount: this.props.ztron.privacyAddress });
    }
  };

  checkValues = () => {
    const { address: pubAddress, privacyAddress, tokenInfo } = this.props.ztron;
    const { fromAccount, toAccount, sendAmount } = this.state;

    let needApprove = false;
    let transferableAmount = '--';
    let singleTransferableAmount = '--';

    let inputError1 = false;
    let inputError2 = false;
    let inputError3 = false;

    let submitEnabled = false;
    let privacySend = false;
    const toAccountType = isInvalidAddress(toAccount);

    if (fromAccount) {
      if (fromAccount === pubAddress && toAccountType === PUBLIC_ADDRESS) {
        inputError1 = intl.get('transfer_error_text2');

        this.setState({ submitEnabled, inputError1, inputError2: false });
        return;
      } else if (toAccountType === PRIVACY_ADDRESS) {
        inputError2 = intl.get('transfer_error_text9'); // TODO, copywrite
        this.setState({ submitEnabled, inputError1: false, inputError2 });
        return;
      }
    }

    if (toAccount) {
      if (toAccountType === ERROR_ADDRESS) {
        inputError2 = intl.get('transfer_error_text8');

        this.setState({ submitEnabled, inputError1: false, inputError2 });
        return;
      } else if (toAccountType === PUBLIC_ADDRESS) {
        if (fromAccount === pubAddress) {
          // pub to pub error
          inputError2 = intl.get('transfer_error_text2');

          this.setState({ submitEnabled, inputError1: false, inputError2 });
          return;
        }
      } else if (toAccountType === PRIVACY_ADDRESS) {
        inputError2 = intl.get('transfer_error_text9'); // TODO, copywrite
        this.setState({ submitEnabled, inputError1: false, inputError2 });
        return;
      }
    }

    if (fromAccount && toAccount) {
      if (fromAccount === toAccount) {
        inputError2 = intl.get('transfer_error_text13');

        this.setState({ submitEnabled, inputError1: false, inputError2 });
        return;
      }

      if (fromAccount === pubAddress) {
        if (toAccountType === PRIVACY_ADDRESS) {
          // mint
          transferableAmount = this.props.ztron.addressBalance;
          singleTransferableAmount = this.props.ztron.addressBalance;
        }
      } else if (fromAccount === privacyAddress) {
        privacySend = true;
        transferableAmount = this.props.ztron.privacyAddressBalance;
        if (toAccountType === PRIVACY_ADDRESS) {
          // transfer
          singleTransferableAmount = this.props.ztron.privacyAddressMaxTransferAmount;
        } else if (toAccountType === PUBLIC_ADDRESS) {
          // burn
          singleTransferableAmount = this.props.ztron.privacyAddressMaxBurnAmount;
        }
      }
    }

    if (singleTransferableAmount === '--') {
      this.setState({ submitEnabled });
      return;
    }

    if (sendAmount) {
      const reg = /^(\d+)(\.\d{0,2})?$/;
      if (!reg.test(sendAmount)) {
        inputError3 = intl.get('transfer_error_text10');
      } else {
        const validValue = sendAmount.replace(/^0+/, '0');
        const sendAmountBg = BigNumber(validValue);
        if (sendAmountBg.gt(BigNumber(singleTransferableAmount))) {
          inputError3 = intl.get('transfer_error_text4');
        } else if (sendAmountBg.lt(0.01)) {
          inputError3 = intl.get('transfer_error_text5');
        }

        if (!inputError3) {
          if (tokenInfo.type === Config.TOKEN_TYPE.TRC20) {
            const allowance = this.props.ztron.addressAllowance;
            if (sendAmountBg.gt(allowance)) {
              needApprove = true;
            }
          }
        }
      }
    }

    if (!inputError3 && fromAccount && toAccount && sendAmount) {
      submitEnabled = true;
    }

    this.setState({
      fromAccount,
      toAccount,
      inputError1: false,
      inputError2: false,
      inputError3,
      singleTransferableAmount,
      transferableAmount,
      privacySend,
      needApprove,
      submitEnabled
    });

    return submitEnabled;
  };

  selectAccount = value => {
    this.setState(
      {
        fromAccount: value,
        transferableAmount: '--',
        singleTransferableAmount: '--'
      },
      () => {
        this.checkValues();
      }
    );
  };

  onToAccountChange = e => {
    this.setState(
      {
        toAccount: e.target.value,
        transferableAmount: '--',
        singleTransferableAmount: '--'
      },
      () => {
        this.checkValues();
      }
    );
  };

  onSendAmountChange = e => {
    const { value } = e.target;
    this.setState(
      {
        sendAmount: value,
        submitEnabled: false
      },
      () => {
        this.checkValues();
      }
    );
  };

  scanQRcode = async () => {
    const code = await iScanQRCode();
    this.setState(
      {
        toAccount: code.qrCode,
        transferableAmount: '--',
        singleTransferableAmount: '--'
      },
      () => {
        this.checkValues();
      }
    );
  };

  clearToAccount = () => {
    this.setState(
      {
        toAccount: '',
        transferableAmount: '--',
        singleTransferableAmount: '--'
      },
      () => {
        this.checkValues();
      }
    );
  };

  onChangeToken = tokenSymbol => {
    this.setState(
      { submitEnabled: false, sendAmount: '', transferableAmount: '--', singleTransferableAmount: '--' },
      () => {
        this.props.ztron.setTokenSymbol(tokenSymbol);
      }
    );
  };

  setMaxAmount = () => {
    const { singleTransferableAmount } = this.state;
    if (singleTransferableAmount === '--') {
      return;
    }
    this.setState(
      {
        sendAmount: BigNumber(this.state.singleTransferableAmount).toFixed(2, 1) // 直接截取小数点后两位
      },
      () => {
        this.checkValues();
      }
    );
  };

  showStepModal = async () => {
    if (!this.state.submitEnabled) return;
    let submitEnabled = false;

    if (this.props.ztron.nodeWaitSync) {
      const inputError1 = intl.get('transfer_error_text1');
      this.setState({ submitEnabled, inputError1, inputError2: false });
      return;
    }
    this.setState({ inputError0: false });
    if (!this.props.ztron.hasActive) {
      console.log('account not actived');
      const inputError0 = intl.get('transfer_error_text11');
      this.setState({ submitEnabled, inputError0 });
      return;
    }
    if (!this.props.ztron.hasPermission) {
      console.log('account has no permission');
      const inputError0 = intl.get('transfer_error_text12');
      this.setState({ submitEnabled, inputError0 });
      return;
    }

    if (this.checkValues()) {
      this.setState({
        stepVisible: true
      });
    }
  };

  cancelStepModal = e => {
    this.setState({
      stepVisible: false,
      spinning: false
    });
  };

  cancelTxidModal = e => {
    this.setState(
      {
        txidVisible: false,
        spinning: false,
        sendAmount: '',
        inputError3: false
      },
      () => {
        const lang = window.localStorage.getItem('lang') || intl.options.currentLocale;
        window.location.href = `/?lang=${lang}#/info`;
      }
    );
  };

  cancelErrorModal = e => {
    this.setState({
      errorVisible: false,
      spinning: false
    });
  };

  beforeTransferToken = () => {
    this.setState({ stepVisible: false, spinning: true }, () => {
      this.transferToken();
    });
  };

  transferToken = async () => {
    const fromAccount = this.state.fromAccount;
    const toAccount = this.state.toAccount;
    const sendAmount = this.state.sendAmount;
    const needApprove = this.state.needApprove;
    const ownerAddress = this.props.ztron.address;
    const tokenInfo = this.props.ztron.tokenInfo;

    let approveRes = false;
    let transferRes = false;

    if (fromAccount === ownerAddress && needApprove) {
      // pub -> pri
      approveRes = await approve(tokenInfo, ownerAddress);
    } else {
      approveRes = true;
    }

    // pub -> pri
    // pri -> pri
    // pri -> pub
    if (approveRes) {
      if (approveRes.code) {
        if (approveRes.code === DECLINED_BY_USER) {
          this.setState({
            errorVisible: false,
            spinning: false
          });
        } else {
          this.setState({
            stepVisible: false,
            errorVisible: true,
            errorMsg: approveRes.message
          });
        }

        return;
      } else {
        transferRes = await transfer({ ...tokenInfo, needApprove }, fromAccount, toAccount, sendAmount, ownerAddress);
      }
    }

    if (transferRes) {
      if (transferRes.code) {
        if (transferRes.code === DECLINED_BY_USER) {
          this.setState({
            errorVisible: false,
            spinning: false
          });
        } else {
          this.setState({
            stepVisible: false,
            errorVisible: true,
            errorMsg: transferRes.message
          });
        }

        return;
      } else {
        this.setState({
          txid: transferRes.txid,
          stepVisible: false,
          txidVisible: true
        });
      }
    }
  };

  render() {
    const {
      fromAccount,
      needApprove,
      inputError0,
      inputError1,
      inputError2,
      inputError3,
      submitEnabled,
      transferableAmount,
      singleTransferableAmount,
      bandwidthCost,
      energyCost,
      privacySend,
      spinning
    } = this.state;
    const { tokenSymbol } = this.props.ztron;

    return (
      <div className="transfer-component">
        <div className="title">
          <Link to={'/info'}>{intl.get('transfer.return')}</Link>
          {intl.get('transfer.title')}
        </div>
        {inputError0 && (
          <div className="transfer-summary-err">
            <ExclamationCircleOutlined style={{ color: '#FF5859', marginRight: '6px' }} />
            {inputError0}
          </div>
        )}
        <div className="transfer-body" id="transfer-body">
          {/* 选择币种 */}
          <div className="title token">{intl.get('transfer.select_token')}</div>
          <Select
            placeholder={intl.get('transfer.please_select_token')}
            className={'select'}
            value={tokenSymbol ? tokenSymbol : undefined}
            onChange={this.onChangeToken}
            dropdownClassName="selectBox"
          >
            {Object.values(Config.tokenMap).map(
              token =>
                token.open && (
                  <Option key={token.symbol} value={token.symbol}>
                    {token.symbolNew || token.symbol}
                  </Option>
                )
              //   <Option key={token.symbol} disabled value={token.symbol}>
              //     {token.symbol}({intl.get('account.not_support')})
              //   </Option>
            )}
          </Select>

          {/* 转出账户 */}
          <div className="title from">{intl.get('transfer_from_account')}</div>
          <Select
            placeholder={intl.get('transfer_select_from_account')}
            className={'select ' + (inputError1 ? 'redBorder' : '')}
            value={fromAccount}
            onChange={this.selectAccount}
            dropdownClassName="selectBox"
          >
            <Option value={this.props.ztron.address} key="0">
              {this.props.ztron.address}
            </Option>
            <Option value={this.props.ztron.privacyAddress} key="1" className="privacyAddress">
              {this.props.ztron.privacyAddress}
            </Option>
            <Option value="disabled" disabled key="2">
              <div className="tipContent">{intl.get('transfer_select_disable')}</div>
            </Option>
          </Select>
          {/* 节点同步未完成 */}
          {inputError1 && <div className="errText">{inputError1}</div>}

          {/* 接收账户 */}
          <div className="title to">{intl.get('transfer_to_account')}</div>
          <Input
            addonAfter={
              this.state.toAccount === '' ? (
                <span className="qrcode">
                  <img src={QRCode} alt="qrcode" onClick={this.scanQRcode} />
                </span>
              ) : (
                <span className="clear" onClick={this.clearToAccount}>
                  ×
                </span>
              )
            }
            value={this.state.toAccount}
            onChange={this.onToAccountChange}
            className={'receive ' + (inputError2 ? 'redBorder' : '')}
          />
          {/* 接收账户错误信息 */}
          {inputError2 && <div className="errText">{inputError2}</div>}

          {/* 转账数量 */}
          <div className="title amount">{intl.get('transfer_amount')}</div>
          <div className="account_general">
            {intl.get('transfer_able_amount')}&nbsp;
            <span className="right">
              <span>{formatNumber(transferableAmount)}</span> {tokenSymbol}
            </span>
          </div>
          <div className="account_general">
            {intl.get('transfer_single_able_amount')}&nbsp;
            <span className="right">
              <span>{formatNumber(singleTransferableAmount)}</span> {tokenSymbol}
            </span>
          </div>
          <Input
            placeholder={intl.get('transfer_min_amount')}
            addonAfter={
              <div className="maxBtn" onClick={this.setMaxAmount}>
                {intl.get('transfer_max_amount')}
              </div>
            }
            value={this.state.sendAmount}
            onChange={this.onSendAmountChange}
            className={'amountInput ' + (inputError3 ? 'redBorder' : '')}
          />
          {/* 转账数量错误信息 */}
          {inputError3 && <div className="errText">{inputError3}</div>}

          {/* 手续费 */}
          <div className="title fee">{intl.get('transfer_fee')}</div>
          <div className="fee_general">
            {intl.get('transfer_bandwidth')}
            <span className="right">
              <span>{formatNumber(bandwidthCost, 0)}</span>
            </span>
          </div>
          <div className="fee_general">
            {intl.get('transfer_energy')}
            <span className="right">
              <span>{formatNumber(energyCost, 0)}</span>
            </span>
          </div>
          {/* 转账须知 */}
          <Card>
            <div>
              <div className="ant-card-title">{intl.get('transfer_note_title')}</div>
              <li>{intl.get('transfer_note_1')}</li>
              <li>{intl.get('transfer_note_2')}</li>
              <li>{intl.get('transfer_note_3')}</li>
            </div>
          </Card>
        </div>
        <div className="transfer-btns">
          <Spin tip="" spinning={spinning} size="small">
            <Button
              type="primary"
              onClick={this.showStepModal}
              block
              className={'btn sendBtn ' + (submitEnabled ? 'blue' : '')}
              // disabled={!submitEnabled}
            >
              {intl.get('transfer_btn_send')}
            </Button>
          </Spin>
        </div>

        <Modal
          visible={this.state.stepVisible}
          onCancel={this.cancelStepModal}
          closable={false}
          footer={null}
          title={null}
          className={`trans-confirm-modal ${this.state.stepVisible && 'active'}`}
          transitionName=""
          width="100%"
        >
          <div className="modal-title">
            <span style={{ position: 'absolute', left: '15px' }} onClick={this.cancelStepModal}>
              <LeftOutlined />
            </span>
            <span>{intl.get('transfer_step_title')}</span>
          </div>
          <div className="modal-content">
            <Steps current={0} direction="vertical" className="trans-steps">
              <Step title={intl.get('transfer_step_1')} icon={<img src={Step1} />} />
              {privacySend ? (
                <Step title={intl.get('transfer_step_2_pr')} icon={<img src={Step2} />} />
              ) : (
                needApprove && <Step title={intl.get('transfer_step_2_pu')} icon={<img src={Step2} />} />
              )}
              <Step title={intl.get('transfer_step_3')} icon={<img src={Step2} />} />
            </Steps>

            <div className="balance-text">
              {this.state.sendAmount} {tokenSymbol}
            </div>
            <div className="confirm-item receive-acnt">
              <span className="c-fff">{intl.get('transfer_step_to_account')}</span>
              <span className="toAccount">{cutMiddle(this.state.toAccount, 10, 12)}</span>
            </div>
            <div className="confirm-item receive-amnt">
              <span className="c-fff">{intl.get('transfer_step_to_amount')}</span>
              <span className="receive-amnt-no">
                {this.state.sendAmount} {tokenSymbol}
              </span>
            </div>
            <Divider style={{ margin: '10px 0' }}></Divider>

            <div className="trans-fee">{intl.get('transfer_fee')}</div>
            <div className="confirm-item receive-bandwidth">
              <span>{intl.get('transfer_bandwidth')}</span>
              <span className="cost">{formatNumber(bandwidthCost, 0)}</span>
            </div>
            <div className="confirm-item receive-energy">
              <span>{intl.get('transfer_energy')}</span>
              <span className="cost">{formatNumber(energyCost, 0)}</span>
            </div>
            <Button className="sendBtn blue" type="primary" onClick={this.beforeTransferToken} block>
              {intl.get('transfer_step_send')}
            </Button>
          </div>
        </Modal>

        <Modal
          title={null}
          closable={false}
          maskClosable={false}
          visible={this.state.txidVisible}
          onCancel={this.cancelTxidModal}
          footer={null}
          className="trans-result-modal"
        >
          <div className="modal-title">{intl.get('transfer_send_title')}</div>
          <div className="modal-text">{intl.get('transfer_send_success')}</div>

          <div className="modal-txid">
            <div>TXID: {this.state.txid}</div>
          </div>

          <Button className="sendBtn blue" type="primary" onClick={this.cancelTxidModal} block>
            {intl.get('transfer_send_cancel')}
          </Button>
        </Modal>

        <Modal
          title={null}
          closable={false}
          visible={this.state.errorVisible}
          onCancel={this.cancelErrorModal}
          footer={null}
          className="trans-result-modal"
        >
          <div className="modal-title">{intl.get('transfer_send_error')}</div>

          <div className="modal-txid">
            <div>Error:</div>
            <div className="modal-error-msg">{this.state.errorMsg}</div>
          </div>

          <Button className="sendBtn blue" type="primary" onClick={this.cancelErrorModal} block>
            {intl.get('transfer_send_cancel')}
          </Button>
        </Modal>
      </div>
    );
  }
}

export default Transfer;
