import React from 'react';

import ReceivePage from '../components/Receive';

class Receive extends React.Component {
  render() {
    return (
      <div>
        <ReceivePage />
      </div>
    );
  }
}

export default Receive;
