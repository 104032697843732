import React from 'react';

import TransferPage from '../components/Transfer';

class Transfer extends React.Component {
  render() {
    return (
      <div>
        <TransferPage />
      </div>
    );
  }
}

export default Transfer;
