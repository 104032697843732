import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { Select } from 'antd';
import Config from '../../config';

import '../../assets/css/guide.scss';

import Logo from '../../assets/images/main-logo.svg';

const { Option } = Select;
import { NoServiceModal } from '../../components/no-service-modal/index.jsx';

@inject('ztron')
@observer
class GuideHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SupportCenterLink: '#/help',
      lang: window.localStorage.getItem('lang') || intl.options.currentLocale
    };
  }

  loadUrl = path => {
    this.setState({ drawVisible: false });
    location.replace(`/?lang=${this.state.lang}#/${path}`);
  };

  setLanguage = lang => {
    this.props.ztron.setLanguage(lang);
  };

  openLink = key => {
    if (key === 'api') {
      window.open(Config.APILink);
    } else if (key === 'github') {
      window.open(Config.githubLink);
    } else if (key === 'manual') {
      this.loadUrl('help');
    } else if (key === 'creation') {
      this.loadUrl('createguide');
    }
  };

  render() {
    return (
      <header className="minWidth">
        <div>
          <h2 className="logo">
            <a href={'#/'}>
              <img src={Logo} alt="logo" />
            </a>
          </h2>
          <div className="right">
            {/* <nav className="menu">
              <a href="#startNow">{intl.get('product_menu_text1')}</a>
            </nav> */}
            {/* <Select
              value={intl.get('product_menu_text2')}
              className="nav-set-lang"
              onChange={this.openLink}
              dropdownClassName="select-auto footer-select-auto"
            >
              <Option value="api">
                <a href={Config.APILink} className="link-a">
                  {intl.get('menu_develop_api')}
                </a>
              </Option>
              <Option value="github">
                <a href={Config.githubLink} className="link-a">
                  {intl.get('menu_develop_github')}
                </a>
              </Option>
            </Select> */}
            {/* <Select
              value={intl.get('product_menu_text3')}
              className="nav-set-lang"
              onChange={this.openLink}
              dropdownClassName="select-auto footer-select-auto"
            >
              <Option value="manual">
                <a href={Config.helpLink} className="link-a">
                  {intl.get('menu_help_manual')}
                </a>
              </Option>
              <Option value="creation">
                <a href={Config.createGuideLink} className="link-a">
                  {intl.get('menu_help_creation')}
                </a>
              </Option>
            </Select> */}
            {/* <Select
              defaultValue={this.state.lang}
              className="nav-set-lang"
              onChange={this.setLanguage}
              dropdownClassName="select-auto footer-select-auto"
            >
              <Option value="zh-CN">简体中文</Option>
              <Option value="en-US">English</Option>
            </Select> */}
          </div>
        </div>
        <NoServiceModal dark={true} />
      </header>
    );
  }
}

export default GuideHeader;
