import React from 'react';
import { Provider } from 'mobx-react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import BigNumber from 'bignumber.js';
import isMobile from 'ismobilejs';
import _ from 'lodash';

import Stores from '../stores';
import Home from './Home';
import Info from './Info';
import Transfer from './Transfer';
import Receive from './Receive';
import Help from './Help';
import Guide from './Guide';
import CreateGuide from './CreateGuide';

import { SUPPOER_LOCALES } from '../utils/index';

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

const locales = {
  'zh-CN': require('../locales/zh-CN.json'),
  'en-US': require('../locales/en-US.json')
};

let TronzHome = Home;

class App extends React.Component {
  constructor(props) {
    super(props);

    if (isMobile(window.navigator).any && Stores.getNewbee()) {
      TronzHome = Info;
    }
  }
  componentDidMount() {
    Stores.initTronLinkWallet();
  }

  componentWillMount() {
    this.loadLocales();
  }

  loadLocales = () => {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang'
    });

    // 如果没找到，则默认为英语
    if (!_.find(SUPPOER_LOCALES, { value: currentLocale })) {
      currentLocale = 'en-US';
    }
    currentLocale = 'en-US';
    // currentLocale = window.localStorage.getItem('lang') || currentLocale;
    // let currentLocale = 'en-US'; // later will deleted
    window.localStorage.setItem('lang', currentLocale);
    // init 方法将根据 currentLocale 来加载当前语言环境的数据
    return intl.init({
      currentLocale,
      locales
    });
  };

  render() {
    return (
      <Provider ztron={Stores}>
        <HashRouter>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          {/* <Route exact path="/" render={() => < Redirect to="/home" />} /> */}
          <Switch>
            {/* <Route path="/home" component={Home}></Route> */}
            <Route path="/transfer" component={Transfer}></Route>
            <Route path="/receive" component={Receive}></Route>
            {/* <Route path="/guide" component={Guide}></Route> */}
            {/* <Route path="/help" component={Help}></Route> */}
            {/* <Route path="/createguide" component={CreateGuide}></Route> */}
            <Route path="/home" component={Home}></Route>
            <Route path="/info" component={Info}></Route>
            <Route path="/" component={TronzHome}></Route>
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
