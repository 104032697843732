import { TOKEN_TYPE, genTokenInfo } from './config';

const Config = {
  USDT: 'TXYZopYRdj2D9XRtbG411XZZ3kM5VkAeBf',
  shieldContractAddress: 'TVaWGs55FAhms53ChyXtU1TNVcfEZcskos',
  poly: 'TGzvgvDHXG24zzLppxMrpcT4inLjW1HhGy',
  tokenMap: {
    USDT: genTokenInfo({
      symbol: 'USDT',
      address: 'TXYZopYRdj2D9XRtbG411XZZ3kM5VkAeBf',
      shieldContract: 'TVaWGs55FAhms53ChyXtU1TNVcfEZcskos',
      scalingFactor: 10
    }),
    USDJ: genTokenInfo({
      symbol: 'USDJ',
      address: 'TLBaRhANQoJFTqre9Nf1mjuwNWjCJeYqUL',
      decimals: 18,
      shieldContract: 'TCsaMicy9mtzAS2B8491gMNeivafPRihy8',
      scalingFactor: 1e9
    }),
    TRX: genTokenInfo({
      symbol: 'TRX',
      shieldContract: 'TTUKPQgjo6Ho9waR1W7yKmdHiWXg6aF8kf',
      type: TOKEN_TYPE.TRX
    }),
    BTTOLD: genTokenInfo({
      symbol: 'BTTOLD',
      tokenId: 1000082,
      shieldContract: 'TLhjFknFPUYJ8E8nyfbzKUzqk1hHrrtgFo',
      type: TOKEN_TYPE.TRC10
    }),
    JST: genTokenInfo({
      symbol: 'JST',
      address: 'TF17BgPaZYbz8oxbjhriubPDsA7ArKoLX3',
      decimals: 18,
      shieldContract: 'TYKhutUntZtsCaxg2xmTTvqhBG4QcC4Nco',
      scalingFactor: 1e9
    }),
    BTTX: genTokenInfo({
      symbol: 'BTTX',
      tokenId: 1000184,
      decimals: 0,
      shieldContract: 'TRsNauZ3vkGmyWbq11yB8rJR5CT62Ehh1T',
      type: TOKEN_TYPE.TRC10,
      open: false
    }),
    JSTX: genTokenInfo({
      symbol: 'JSTX',
      address: 'THtxesgtxJpQsy9bThtn6oomQzKWRKwgx6',
      decimals: 18,
      shieldContract: 'TNMyfHkpVq1kwgQvrrxszeL8UVpx7JQV1i',
      open: false
    }),
    WIN: genTokenInfo({
      symbol: 'WIN',
      address: '',
      shieldContract: '',
      open: false
    })
  },
  transaction: 'https://nile.tronscan.io/#/transaction/'
};
export default Config;
