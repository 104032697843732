import BigNumber from 'bignumber.js';
import intl from 'react-intl-universal';
import { isTronLink, iVersion } from '../utils/iTron';

export const tabAnimate = (e, component) => {
  let domName = component + '-animate-block';
  let animateBlock = document.querySelector('.' + domName);
  let tabKey = /tabAni/;
  for (let ani of animateBlock.classList) {
    if (animateBlock.classList.contains('hide')) {
      animateBlock.classList.remove('hide');
    }
    let res = ani.match(new RegExp(tabKey));
    if (res) {
      animateBlock.classList.remove(res.input);
    }
  }
  let tab = document.getElementById(component + '-tab');
  let tabNav = tab.querySelector('.ant-tabs-nav');
  tabNav.style.background = 'none';
  let tabActive = tabNav.querySelectorAll('.ant-tabs-tab')[0];
  tabActive.style.background = 'none';
  tab.appendChild(animateBlock);
  animateBlock.classList.add('tabAni' + e);
};

export const copyToClipboard = (e, disTop = '6px', p = false) => {
  let value = '';

  if (p) {
    value = document.getElementById(p).title;
  } else {
    value = e.currentTarget.title;
  }
  value = value && value.replace(/,/g, '');

  var aux = document.createElement('input');

  if (BigNumber.isBigNumber(value)) {
    aux.setAttribute('value', toBigNumber(value).valueOf());
  } else {
    aux.setAttribute('value', value.valueOf());
  }

  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
  const div = document.createElement('div');
  div.innerHTML = intl.get('copy_text');
  div.className = 'copy_text';
  // e.currentTarget.style.opacity = 0.4;
  Object.assign(div.style, {
    position: 'absolute',
    fontSize: '14px',
    border: 'none',
    color: '#fff',
    padding: '0',
    background: '#80838d',
    marginTop: disTop,
    borderRadius: '8px',
    zIndex: '9000',
    textAlign: 'center',
    lineHeight: '40px',
    width: '80px',
    fontWeight: '600',
    letterSpacing: '0.01rem',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    zIndex: 999,
    right: '-5px',
    height: '40px',
    opacity: 1
  });
  if (p) {
    document.getElementById(p).appendChild(div);
  } else {
    e.currentTarget.appendChild(div);
  }
  const parent = p ? document.getElementById(p) : e.currentTarget;
  const img = e.currentTarget;
  setTimeout(() => {
    img.style.opacity = 1;
    parent.removeChild(div);
  }, 1000);
};

export const cutMiddle = (text = '', left = 4, right = 4) => {
  if (typeof text !== 'string') return '';

  text = text.trim();
  if (text.length <= left + right) return text;
  return `${text.substring(0, left)}...${text.substring(text.length, text.length - right)}`;
};

export const formatNumber = (_num, dp = 2, rm = 1) => {
  if (_num === '--') return '--';
  return BigNumber(_num).toFormat(dp, rm);
};

export const formatDate = timestamp => {
  const d = new Date(timestamp);

  var setFormat = function (time) {
    if (Number(time) < 10) {
      time = '0' + time;
    }
    return time;
  };
  return (
    d.getFullYear() +
    '-' +
    setFormat(d.getMonth() + 1) +
    '-' +
    setFormat(d.getDate()) +
    '  ' +
    setFormat(d.getHours()) +
    ':' +
    setFormat(d.getMinutes()) +
    ':' +
    setFormat(d.getSeconds())
  );
};

export const checkVersion = _version => {
  if (!isTronLink()) return false;

  try {
    // X.Y.Z Version
    const getVersion = str => {
      const xPow = 1e8;
      const yPow = 1e4;
      const zPow = 1;
      const vers = str.split('.');
      let num = 0;
      num += vers[0] * xPow;
      num += vers[1] * yPow;
      num += vers[2] * zPow;

      return num;
    };
    const appVersion = iVersion();

    const latest = getVersion(_version);
    const current = getVersion(appVersion);

    if (latest > current) {
      // 版本过低
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('checkVersion error:', error.message ? error.message : error);
    return true;
  }
};
