import { TOKEN_TYPE, genTokenInfo } from './config';

const Config = {
  USDT: 'TZJQHgERssgG6pqkqT5rRvdd8XhoXSjPtN',
  shieldContractAddress: 'TJETvj7w7YoDFu9jxC3DLt5avgn1j82SXW',
  poly: 'TYsT2bhJ8BAP1TKcdE6MW1zCqn9WiXhMNE',
  tokenMap: {
    USDT: genTokenInfo({
      symbol: 'USDT',
      address: 'TZJQHgERssgG6pqkqT5rRvdd8XhoXSjPtN',
      shieldContract: 'TJETvj7w7YoDFu9jxC3DLt5avgn1j82SXW'
    }),
    USDJ: genTokenInfo({
      symbol: 'USDJ',
      address: 'TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT',
      decimals: 18,
      shieldContract: 'TN2CytJakVXNagWcTu3BKtaHQjrVEvjGkb',
      scalingFactor: 1e9
    }),
    TRX: genTokenInfo({
      symbol: 'TRX',
      shieldContract: 'TWukE3P7LDPgaHX9GcphGZfsikoqzuxbey',
      type: TOKEN_TYPE.TRX
    }),
    BTTOLD: genTokenInfo({
      symbol: 'BTTOLD',
      tokenId: 1002000,
      shieldContract: 'TBS1DnnmEi6NVgpCwboDre14EYio2vZgpF',
      type: TOKEN_TYPE.TRC10,
      open: false
    }),
    JST: genTokenInfo({
      symbol: 'JST',
      address: '',
      decimals: 18,
      shieldContract: '',
      open: false
    }),
    WIN: genTokenInfo({
      symbol: 'WIN',
      address: '',
      shieldContract: '',
      open: false
    })
  }
};
export default Config;
