import React from 'react';

import GuidePage from '../components/Guide';

class Guide extends React.Component {
  render() {
    return (
      <div>
        <GuidePage />
      </div>
    );
  }
}

export default Guide;
