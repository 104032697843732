import intl from 'react-intl-universal';
import BigNumber from 'bignumber.js';
import Config from '../config';
import { zBuildShieldParams, zTriggerSmartContract, zSignShieldParams, zSign, zBroadcastTransaction } from './zTronWeb';

const DATA_LEN = 64;
export const MAX_UINT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const MAX_APPROVE = BigNumber(10)
  .pow(18 + 18)
  .toString();

export const approve = async (tokenInfo, ownerAddress) => {
  try {
    const triggerTran = await zTriggerSmartContract({
      contractAddrStr: tokenInfo.address,
      methodStr: 'approve(address,uint256)',
      argsStr: `${tokenInfo.shieldContract},${MAX_APPROVE}`,
      ownerAddress,
      feeLimit: Config.feeLimit
    });
    const options = {
      shieldType: 'mint',
      currentStep: 2,
      optionsTexts: [intl.get('transfer_step_1'), intl.get('transfer_step_2_pu'), intl.get('transfer_step_3')]
    };

    console.log('approve test 1: ', triggerTran);
    const signedTransaction = await zSign(triggerTran.transaction, options);
    console.log('approve test 2: ', signedTransaction);
    const result = await zBroadcastTransaction(signedTransaction.transaction);
    console.log('approve test 3: ', result);

    return result;
  } catch (error) {
    console.log(`approve error: `, error.message);
    return error;
  }
};

export const transfer = async (tokenInfo, fromAddress, toAddress, amount, ownerAddress) => {
  const tokenType = tokenInfo.type;
  const amountStr = BigNumber(amount).toString();
  let shieldType = 'send';

  try {
    const buildOpt = {
      fromAddress,
      toAddress,
      amount: amountStr,
      isHex: true,
      shieldAddress: tokenInfo.shieldContract,
      tokenType,
      precision: tokenInfo.decimals,
      scalingFactor: tokenInfo.scalingFactor
    };
    if (tokenType == Config.TOKEN_TYPE.TRC20) {
      buildOpt.tokenAddress = tokenInfo.address;
    }
    if (tokenType == Config.TOKEN_TYPE.TRC10) {
      buildOpt.tokenId = tokenInfo.tokenId;
    }

    console.log('transfer 0', buildOpt);

    const buildParams = await zBuildShieldParams(buildOpt);

    console.log('transfer 1: ', buildParams);

    let signShieldTran = {};
    let paramsSignatures = [];

    let triggerParams = {};

    shieldType = buildParams.shieldType;
    let options = {};
    let nextStep = 3;
    switch (shieldType) {
      case 'mint':
        triggerParams.methodStr = 'mint(uint256,bytes32[9],bytes32[2],bytes32[21])';
        triggerParams.fromAmount = amountStr;
        if (tokenInfo.type === Config.TOKEN_TYPE.TRC10) {
          triggerParams.tokenId = tokenInfo.tokenId;
          triggerParams.tokenCallValue = BigNumber(amount).times(tokenInfo.precision).toString();
        }
        if (tokenInfo.type === Config.TOKEN_TYPE.TRX) {
          triggerParams.callValue = BigNumber(amount).times(tokenInfo.precision).toString();
        }

        options.shieldType = 'mint';
        if (tokenInfo.needApprove) {
          options.currentStep = 3;
          options.optionsTexts = [
            intl.get('transfer_step_1'),
            intl.get('transfer_step_2_pu'),
            intl.get('transfer_step_3')
          ];
        } else {
          options.currentStep = 2;
          options.optionsTexts = [intl.get('transfer_step_1'), intl.get('transfer_step_3')];
          nextStep = 2;
        }
        break;
      case 'transfer':
        triggerParams.methodStr = 'transfer(bytes32[10][],bytes32[2][],bytes32[9][],bytes32[2],bytes32[21][])';
        triggerParams.fromAmount = amountStr;
        options = {
          shieldType: 'transfer',
          currentStep: 2,
          optionsTexts: [intl.get('transfer_step_1'), intl.get('transfer_step_2_pr'), intl.get('transfer_step_3')]
        };
        signShieldTran = await zSignShieldParams(
          {
            alphas: buildParams.alphas,
            shieldParam: JSON.stringify(buildParams.params)
          },
          options
        );
        paramsSignatures = signShieldTran.paramsSignatures;
        break;
      case 'burn':
        triggerParams.methodStr =
          'burn(bytes32[10],bytes32[2],uint256,bytes32[2],address,bytes32[3],bytes32[9][],bytes32[21][])';
        triggerParams.toAmount = amountStr;
        triggerParams.toAddress = toAddress;
        options = {
          shieldType: 'burn',
          currentStep: 2,
          optionsTexts: [intl.get('transfer_step_1'), intl.get('transfer_step_2_pr'), intl.get('transfer_step_3')]
        };
        signShieldTran = await zSignShieldParams(
          {
            alphas: buildParams.alphas,
            shieldParam: JSON.stringify(buildParams.params)
          },
          options
        );
        paramsSignatures = signShieldTran.paramsSignatures;
        break;
      default:
        break;
    }

    const triggerTran = await zTriggerSmartContract(
      Object.assign(
        {
          contractAddrStr: tokenInfo.shieldContract,
          argsStr: JSON.stringify(buildParams.params),
          isHex: true,
          ownerAddress,
          shieldType: buildParams.shieldType,
          needEncode: true,
          paramsSignatures,
          tokenType: tokenInfo.type,
          precision: tokenInfo.decimals,
          scalingFactor: tokenInfo.scalingFactor,
          feeLimit: Config.feeLimit,
        },
        triggerParams
      )
    );

    console.log('transfer 2: ', triggerTran);
    options.currentStep = nextStep;
    const signedTransaction = await zSign(triggerTran.transaction, options);
    console.log('transfer 3: ', signedTransaction);
    const result = await zBroadcastTransaction(signedTransaction.transaction);
    console.log('transfer 4: ', result);

    return result;
  } catch (error) {
    console.log(`${shieldType}: `, error.message);
    return error;
  }
};

export const SUPPOER_LOCALES = [
  {
    name: 'English',
    value: 'en-US'
  },
  {
    name: '简体中文',
    value: 'zh-CN'
  }
];

export const view = async (address, functionSelector, parameters = []) => {
  try {
    const result = await window.tronWeb.transactionBuilder.triggerSmartContract(
      address,
      functionSelector,
      { _isConstant: true },
      parameters
    );
    return result && result.result ? result.constant_result : [];
  } catch (error) {
    console.log(
      `view error ${address} - ${functionSelector} - ${JSON.stringify(parameters)}`,
      error.message ? error.message : error
    );
    return [];
  }
};

export const getBalance = async (tokenInfo, address) => {
  let token = Config.defaultAddress;
  let target = Config.defaultAddress;
  let tokenId = 0;
  if (tokenInfo.type === Config.TOKEN_TYPE.TRC20) {
    token = tokenInfo.address;
    target = tokenInfo.shieldContract;
  }
  if (tokenInfo.type === Config.TOKEN_TYPE.TRC10) {
    tokenId = tokenInfo.tokenId;
  }

  const result = await view(
    Config.poly,
    'balanceOf(address,address,address,uint256)',
    [
      { type: 'address', value: address },
      { type: 'address', value: token },
      { type: 'address', value: target },
      { type: 'uint256', value: tokenId }
    ],
    {},
    true
  );

  let balance = new BigNumber(0);
  let allowance = new BigNumber(0);
  let success = false;

  if (result.length) {
    const data = result[0];
    let dataIndex = 0;
    balance = new BigNumber(data.substr(dataIndex++ * DATA_LEN, DATA_LEN), 16).div(tokenInfo.precision);
    allowance = new BigNumber(data.substr(dataIndex++ * DATA_LEN, DATA_LEN), 16);
    success = true;
  }

  return {
    balance,
    allowance,
    success
  };
};
