import { observable } from 'mobx';
import BigNumber from 'bignumber.js';

import { getBalance } from '../utils';
import { checkVersion } from '../utils/helper';
import { getPrivacyAddress, getBlocks, getPrivacyBalance, getPrivacyUTXOByToken } from '../utils/zTronWeb';
import Config from '../config';

class ZTronStore {
  @observable tronWeb = false;
  @observable zTron = false;
  @observable pubConnected = false; // 是否有公开地址
  @observable priConnected = false; // 是否有匿名地址
  @observable privacyAddress = '';
  @observable privacyAddressName = '';
  @observable privacyAddressBalance = '--';
  @observable privacyAddressMaxTransferAmount = '--';
  @observable privacyAddressMaxBurnAmount = '--';
  @observable address = '';
  @observable addressName = '';
  @observable addressBalance = '--';
  @observable addressAllowance = 0;
  @observable addressTrx = '--';
  @observable addressBandwidth = '--';
  @observable addressEnergy = '--';
  @observable blockHeight = '';
  @observable history = {};
  @observable blockData = { currentBlock: '--', totalBlocks: '--' };
  @observable locationStr = 'home';
  @observable nodeWaitSync = false;
  @observable pageNo = 1;
  @observable pageSize = 5;
  @observable hasActive = false;
  @observable hasPermission = false;
  @observable transferComponent = {};
  @observable accountComponent = {};
  @observable tokenSymbol = 'USDT';
  @observable tokenInfo = Config.tokenMap['USDT'];

  constructor() {
    this.interval = null;
    this.getTokenSymbol();
  }

  setLocationStr = str => {
    this.locationStr = str;
  };

  setData = (key, value) => {
    this[key] = value;
  };

  getHistoryData = async (interval = false) => {
    if (this.pageNo != 1 && interval) {
      return;
    }
    const _tokenSymbol = this.tokenSymbol;
    const params = {
      shieldAddress: this.tokenInfo.shieldContract,
      scalingFactor: this.tokenInfo.scalingFactor,
      tokenType: this.tokenInfo.type,
      precision: this.tokenInfo.decimals,
      pageNo: this.pageNo,
      pageSize: this.pageSize
    };
    if (this.tokenInfo.type === Config.TOKEN_TYPE.TRC20) {
      params.tokenAddress = this.tokenInfo.address;
    }
    if (this.tokenInfo.type === Config.TOKEN_TYPE.TRC10) {
      params.tokenId = this.tokenInfo.tokenId;
    }
    const records = await getPrivacyUTXOByToken(params);
    if (_tokenSymbol !== this.tokenSymbol) return;
    this.history = records;
  };

  getPriBalanceData = async () => {
    try {
      const _tokenSymbol = this.tokenSymbol;
      const params = {
        shieldAddress: this.tokenInfo.shieldContract,
        scalingFactor: this.tokenInfo.scalingFactor,
        tokenType: this.tokenInfo.type,
        precision: this.tokenInfo.decimals
      };
      if (this.tokenInfo.type === Config.TOKEN_TYPE.TRC20) {
        params.tokenAddress = this.tokenInfo.address;
      }
      if (this.tokenInfo.type === Config.TOKEN_TYPE.TRC10) {
        params.tokenId = this.tokenInfo.tokenId;
      }

      const balanceDataArr = await getPrivacyBalance(params);
      if (_tokenSymbol !== this.tokenSymbol) return;
      let balanceData = {};
      if (balanceDataArr.length > 0) {
        balanceData = balanceDataArr.filter(item => item.shieldAddress === this.tokenInfo.shieldContract)[0];
      }
      if (!balanceData) return;

      this.privacyAddressBalance = balanceData.balance === undefined ? '--' : balanceData.balance;
      this.privacyAddressMaxTransferAmount =
        balanceData.maxTransferAmount === undefined ? '--' : balanceData.maxTransferAmount;
      this.privacyAddressMaxBurnAmount = balanceData.maxBurnAmount === undefined ? '--' : balanceData.maxBurnAmount;
      this.nodeWaitSync = false;

      this.transferComponent.checkValues && this.transferComponent.checkValues();
    } catch (error) {
      console.log('getPrivacyBalance error: ', error.message ? error.message : error);
      this.privacyAddressBalance = '--';
      this.nodeWaitSync = true;
    }
  };

  getPubBalanceData = async () => {
    const _tokenSymbol = this.tokenSymbol;
    const { success, balance, allowance } = await getBalance(this.tokenInfo, this.address);
    if (_tokenSymbol !== this.tokenSymbol) return;
    if (success) {
      this.addressBalance = balance.toString(); // ???
      this.addressAllowance = allowance;
    }
  };

  checkPermission = data => {
    if (!!data.owner_permission) {
      const keys = data.owner_permission.keys;
      if (keys && keys.length > 0) {
        const per = keys.filter(item => item.address === window.tronWeb.address.toHex(this.address));
        if (per.length > 0) {
          if (Number(per[0].weight) < Number(data.owner_permission.threshold)) {
            return false;
          }
          return true;
        }
      }
      return true;
    }
    return true;
  };

  checkActiveAccount = data => {
    if (this.hasActive) {
      return;
    }
    if (!data.address) {
      this.hasActive = false;
    } else {
      this.hasActive = true;
    }
  };

  updateResource = data => {
    const freeNetUsed = data.freeNetUsed === undefined ? BigNumber(0) : BigNumber(data.freeNetUsed);
    const freeNetLimit = data.freeNetLimit === undefined ? BigNumber(0) : BigNumber(data.freeNetLimit);
    const NetUsed = data.NetUsed === undefined ? BigNumber(0) : BigNumber(data.NetUsed);
    const NetLimit = data.NetLimit === undefined ? BigNumber(0) : BigNumber(data.NetLimit);
    const EnergyUsed = data.EnergyUsed === undefined ? BigNumber(0) : BigNumber(data.EnergyUsed);
    const EnergyLimit = data.EnergyLimit === undefined ? BigNumber(0) : BigNumber(data.EnergyLimit);

    this.addressBandwidth = freeNetLimit.plus(NetLimit).minus(freeNetUsed).minus(NetUsed);
    this.addressEnergy = EnergyLimit.minus(EnergyUsed);

    this.addressBandwidth = this.addressBandwidth.gt(0) ? this.addressBandwidth : BigNumber(0);
    this.addressEnergy = this.addressEnergy.gt(0) ? this.addressEnergy : BigNumber(0);

    this.accountComponent.checkResource && this.accountComponent.checkResource();
  };

  getPubResource = async () => {
    try {
      const accountPromise = window.tronWeb.trx.getAccount(this.address);
      const resourcePromise = window.tronWeb.trx.getAccountResources(this.address);
      const data = await accountPromise;
      const resource = await resourcePromise;

      this.checkActiveAccount(data);
      this.hasPermission = this.checkPermission(data);
      this.addressTrx =
        data.balance === undefined ? BigNumber(0) : BigNumber(data.balance).div(Config.tokenMap.TRX.precision);
      this.updateResource(resource);
    } catch (error) {
      console.log(`getPubResource error: `, error.message ? error.message : error);
    }
  };

  setVaribalesInterval = async () => {
    if (this.interval) {
      return;
    }
    this.interval = setInterval(async () => {
      if (this.zTron && this.privacyAddress) {
        this.blockData = getBlocks(this.blockData);
        this.getPriBalanceData();
        this.getHistoryData(true);
        this.getPubBalanceData();
        this.getPubResource();
      }
    }, 6000); // 3s获取历史记录，app端反应太快，调整成6s.对于余额数据看是否需要调整
    this.reloadInfo();
  };

  reloadInfo = async () => {
    if (this.zTron && this.privacyAddress) {
      this.getPriBalanceData();
      this.getHistoryData();
    }
    this.getPubBalanceData();
    this.getPubResource();
  };

  getNewbee = () => {
    return window.localStorage.getItem(Config.newbeeKey) || false;
  };

  setNewbee = () => {
    window.localStorage.setItem(Config.newbeeKey, true);
  };

  initTronLinkWallet = async (cb, cbn) => {
    try {
      let timeCount = 0;
      const tmpTimer1 = setInterval(async () => {
        timeCount++;
        if (timeCount > 3) {
          cbn && cbn();
          clearInterval(tmpTimer1);
          return;
        }
        // for public address
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          if (process.env.REACT_APP_ENV === 'test') {
            window.tronWeb.setFullNode('https://api.nileex.io');
            window.tronWeb.setSolidityNode('https://api.nileex.io');
          }

          if (window.tronWeb.setHeader && window.tronWeb.fullNode.host === Config.trongrid.host) {
            window.tronWeb.setHeader({ [Config.trongrid.header]: Config.trongrid.key });
          }

          this.tronWeb = window.tronWeb;
          this.address = this.tronWeb.defaultAddress.base58;
          this.pubConnected = true;
          this.getPubBalanceData();
          this.getPubResource();
        } else {
          this.pubConnected = false;
        }
        if (window.zTron) {
          // 逻辑可能需要更改，得根据返回的privacyAddress来判断
          this.zTron = window.zTron;
          const address = await getPrivacyAddress({
            tokenAddress: Config.tokenMap['USDT'].address,
            shieldAddress: Config.tokenMap['USDT'].shieldContract
          });
          if (address) {
            this.setVaribalesInterval();
            this.priConnected = true;
            this.privacyAddress = address;
          }
        } else {
          this.priConnected = false;
        }

        if (this.pubConnected && this.priConnected) {
          clearInterval(tmpTimer1);
          cb && cb();
        }
      }, 1000);
    } catch (error) {
      console.log('initTronLinkWallet error: ', error.message ? error.message : error);
    }
  };

  setLanguage = lang => {
    window.localStorage.setItem('lang', lang);
    window.location.search = `?lang=${lang}`;
  };

  initBalanceData = tokenSymbol => {
    const _tokenSymbol = this.tokenSymbol;
    if (_tokenSymbol === tokenSymbol) return;

    this.privacyAddressBalance = '--';
    this.privacyAddressMaxTransferAmount = '--';
    this.privacyAddressMaxBurnAmount = '--';
    this.addressBalance = '--';
    this.addressAllowance = 0;
    this.addressTrx = '--';
    this.addressBandwidth = '--';
    this.addressEnergy = '--';
    this.history = {};
    this.pageNo = 1;
    this.tokenSymbol = tokenSymbol;
    this.tokenInfo = Config.tokenMap[tokenSymbol];

    this.reloadInfo();
  };

  setTokenSymbol = tokenSymbol => {
    if (checkVersion(Config.tokenAppVersion)) {
      tokenSymbol = 'USDT';
    }

    this.initBalanceData(tokenSymbol);

    const key = Config.localStorage.selectedToken;
    window.localStorage.setItem(key, tokenSymbol);
  };

  getTokenSymbol = () => {
    const key = Config.localStorage.selectedToken;
    const tokens = [];
    Object.values(Config.tokenMap).map(token => {
      if (token.open) {
        tokens.push(token.symbol);
      }
    });

    let tokenSymbol = window.localStorage.getItem(key);
    if (!tokens.includes(tokenSymbol)) {
      tokenSymbol = tokens[0] || 'USDT';
    }

    if (checkVersion(Config.tokenAppVersion)) {
      tokenSymbol = 'USDT';
    }

    this.tokenSymbol = tokenSymbol;
    this.tokenInfo = Config.tokenMap[tokenSymbol];
    return tokenSymbol;
  };
}

const ZTron = new ZTronStore();
export default ZTron;
