import intl from 'react-intl-universal';

const createCbFunc = (callbackName, resolve, reject) => {
  window[callbackName] = data => {
    try {
      // console.log(`${callbackName}: ${data}`);
      const d = JSON.parse(data);
      if (d.code == 0) {
        resolve(d.data);
      } else {
        // const message = d.message; // d.code === BROADCAST_ERROR
        d.message = intl.get(`ztron_error_${d.code}`);
        d.message = d.message ? d.message : intl.get('ztron_error_unknow');
        reject(d);
      }
    } catch (error) {
      console.log(`ztron error: ${data} ${error.message}`);
      reject({ code: 404, message: intl.get('ztron_error_unknow') });
    }
    delete window.callbackName;
  };
};

export const zBuildShieldParams = options => {
  return new Promise((resolve, reject) => {
    const callbackName = `_zBuildShieldParams_${Math.random().toString(36).slice(2)}`;
    window.zTron.buildShieldParams(JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const zSignShieldParams = (params, options) => {
  return new Promise((resolve, reject) => {
    const callbackName = `_zSignShieldParams_${Math.random().toString(36).slice(2)}`;
    window.zTron.signShieldParams(JSON.stringify(params), JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const zTriggerSmartContract = options => {
  return new Promise((resolve, reject) => {
    const callbackName = `_zTriggerSmartContract_${Math.random().toString(36).slice(2)}`;
    window.zTron.triggerSmartContract(JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const zSign = (params, options) => {
  return new Promise((resolve, reject) => {
    const callbackName = `_zSignTransaction_${Math.random().toString(36).slice(2)}`;
    window.zTron.signTransaction(JSON.stringify(params), JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const zBroadcastTransaction = options => {
  return new Promise((resolve, reject) => {
    const callbackName = `_zBroadcastTransaction_${Math.random().toString(36).slice(2)}`;
    window.zTron.broadcastTransaction(JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const getPrivacyBalance = options => {
  return new Promise((resolve, reject) => {
    const callbackName = `_getPrivacyBalance_${Math.random().toString(36).slice(2)}`;
    window.zTron.getPrivacyBalance(JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const getPrivacyUTXOByToken = options => {
  return new Promise((resolve, reject) => {
    const callbackName = `_getPrivacyUTXOByToken_${Math.random().toString(36).slice(2)}`;
    window.zTron.getPrivacyUTXOByToken(JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const isInvalidAddress = options => {
  return window.zTron.isInvalidAddress(options);
};

export const getPrivacyAddress = options => {
  return new Promise((resolve, reject) => {
    const callbackName = `_getPrivacyAddress_${Math.random().toString(36).slice(2)}`;
    window.zTron.getPrivacyAddress(JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const getBlocks = preBlocks => {
  // console.log(`getBlocks${window.zTron.getBlocks()}`);
  const blocks = window.zTron.getBlocks();
  try {
    const b = JSON.parse(blocks);
    if (b.code) {
      // ??? error 怎么传
      return preBlocks;
    } else {
      return b.data;
    }
  } catch (error) {
    console.log(`Parse error: ${blocks}`);
    return preBlocks;
  }
};

export const BROADCAST_ERROR = 10004;
export const DECLINED_BY_USER = 10006;

export const PUBLIC_ADDRESS = '1';
export const PRIVACY_ADDRESS = '0';
export const ERROR_ADDRESS = '2';
