import intl from 'react-intl-universal';

const createCbFunc = (callbackName, resolve, reject) => {
  window[callbackName] = data => {
    try {
      const d = JSON.parse(data);
      if (d.code == 0) {
        resolve(d.data);
      } else {
        // for test
        // alert(data)
        reject(d);
      }
    } catch (error) {
      console.log(`itron error: ${data} ${error.message}`);
      reject({ code: 404, message: intl.get('ztron_error_unknow') });
    }
    delete window.callbackName;
  };
};

export const iSign = options => {
  return new Promise((resolve, reject) => {
    const callbackName = `iSign_${Math.random().toString(36).slice(2)}`;
    window.iTron.sign(JSON.stringify(options), callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const iScanQRCode = () => {
  return new Promise((resolve, reject) => {
    const callbackName = `iScanQRCode_${Math.random().toString(36).slice(2)}`;
    window.iTron.scanQRCode(callbackName);
    createCbFunc(callbackName, resolve, reject);
  });
};

export const iVersion = () => {
  return window.iTron.getCurrentVersion().split('&&&')[1];
};

export const isTronLink = () => {
  return window.iTron ? true : false;
};
